import { L10N } from 'global/localization'
import { ButtonColor } from 'global/types/enums'
import type { TrxHistoryDetailFooterProps } from 'global/types/props'
import { Button } from 'shared/components/button'

export const TrxHistoryDetailFooter = ({
  trxDetail,
  // isLoading = false,
  onClick
}: TrxHistoryDetailFooterProps) => {
  switch (trxDetail.invoiceStatus.toLowerCase()) {
    // case 'paid':
    //   return (
    //     <div className='grid grid-cols-1 p-4'>
    //       <Button
    //         label={L10N.LABEL.DOWNLOAD_INVOICE}
    //         icon='arrow-down'
    //         color={ButtonColor.WhiteBordered}
    //         isLoading={isLoading}
    //         onClick={() => onClick?.(L10N.LABEL.DOWNLOAD_INVOICE)}
    //       />
    //     </div>
    //   )

    case 'pending':
      return (
        <div className='grid grid-cols-2 gap-4 p-4'>
          <Button
            label={L10N.LABEL.CANCEL}
            color={ButtonColor.WhiteBordered}
            onClick={() => onClick?.(L10N.LABEL.CANCEL)}
          />
          <Button
            label={L10N.LABEL.GO_TO_PAYMENT}
            color={ButtonColor.Primary}
            onClick={() => onClick?.(L10N.LABEL.GO_TO_PAYMENT)}
          />
        </div>
      )

    default:
      return null
  }
}
