import { API } from 'global/api'
import { CONSTANT } from 'global/constant'
import type { CarListItemResponse, PaginatedResponse } from 'global/types/responses'
import { api, headers } from './api'

const carApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCarList: builder.query<PaginatedResponse<CarListItemResponse>, void>({
      query: () => ({
        url: API.CAR_LIST_URL,
        method: 'GET',
        headers: headers()
      }),
      providesTags: [CONSTANT.CAR_TAG]
    })
  })
})

export const { useLazyGetCarListQuery } = carApi
