import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { OrderInitialState } from 'global/types/states'
import type { RootState } from 'store/store'

const initialState: OrderInitialState = {
  promoCode: '',
  promoAmount: 0,
  paymentMethod: '',
  paymentPrice: 0,
  paymentTotal: 0,
  isEnableAutoRenewal: false,
  isAgreeTnc: false,
  isAgreePrivacyPolicy: false
}

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setPromo: (
      state,
      action: PayloadAction<Pick<OrderInitialState, 'promoCode' | 'promoAmount'>>
    ) => {
      state.promoCode = action.payload.promoCode
      state.promoAmount = action.payload.promoAmount
    },
    setPaymentMethod: (state, action: PayloadAction<string>) => {
      state.paymentMethod = action.payload
    },
    setPaymentPrice: (state, action: PayloadAction<number>) => {
      state.paymentPrice = action.payload
    },
    setPaymentTotal: (state, action: PayloadAction<number>) => {
      state.paymentTotal = action.payload
    },
    setEnableAutoRenewal: (state, action: PayloadAction<boolean>) => {
      state.isEnableAutoRenewal = action.payload
    },
    setAgreeTnc: (state, action: PayloadAction<boolean>) => {
      state.isAgreeTnc = action.payload
    },
    setAgreePrivacyPolicy: (state, action: PayloadAction<boolean>) => {
      state.isAgreePrivacyPolicy = action.payload
    },
    resetOrder: (state) => {
      state.promoCode = initialState.promoCode
      state.promoAmount = initialState.promoAmount
      state.paymentMethod = initialState.paymentMethod
      state.paymentPrice = initialState.paymentPrice
      state.paymentTotal = initialState.paymentTotal
      state.isEnableAutoRenewal = initialState.isEnableAutoRenewal
      state.isAgreeTnc = initialState.isAgreeTnc
      state.isAgreePrivacyPolicy = initialState.isAgreePrivacyPolicy
    }
  }
})

export const {
  setPromo,
  setPaymentMethod,
  setPaymentPrice,
  setPaymentTotal,
  setEnableAutoRenewal,
  setAgreeTnc,
  setAgreePrivacyPolicy,
  resetOrder
} = orderSlice.actions

export const getOrderState = (state: RootState) => state.order

export const orderReducer = orderSlice.reducer
