import { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { L10N } from 'global/localization'
import { ButtonColor, LayoutType } from 'global/types/enums'
import type { BaseErrorResponse, CheckPromoCodeResponse } from 'global/types/responses'
import { useAppDispatch } from 'store/store'
import { useCheckPromoCodeMutation } from 'store/promo-code'
import { getOrderState, setPromo } from 'store/slices/order'
import { Button } from 'shared/components/button'
import { Input } from 'shared/components/input'
import { Layout } from 'shared/components/layout'
import { useBack } from 'shared/services/hooks'
import { formatCurrency } from 'shared/services/utils'
import { EnterPromoFooter } from './footer'

export const EnterPromo = () => {
  const { id } = useParams()

  const { navigateBack } = useBack()

  const dispatch = useAppDispatch()

  const order = useSelector(getOrderState)

  const [checkPromoCode, checkPromoCodeResult] = useCheckPromoCodeMutation()

  const [code, setCode] = useState<string>('')

  const [message, setMessage] = useState<JSX.Element>()

  const [messageColor, setMessageColor] = useState<string>('text-black')

  useEffect(() => {
    if (checkPromoCodeResult.isSuccess && checkPromoCodeResult.data) {
      setMessageColor('')
      setMessage(getSuccessMessage(checkPromoCodeResult.data))
    } else if (checkPromoCodeResult.isError) {
      const error = checkPromoCodeResult.error
        ? ((checkPromoCodeResult.error as FetchBaseQueryError).data as BaseErrorResponse)
        : undefined

      setMessageColor('text-danger')
      setMessage(<>{error?.errorMessage || L10N.MESSAGE.FALLBACK_ERROR_MESSAGE}</>)
    }
  }, [checkPromoCodeResult.isSuccess, checkPromoCodeResult.isError])

  const inputCode = (text: string) => {
    setCode(text.toUpperCase())
    setMessage(undefined)
  }

  const resetCode = () => {
    setCode('')
    setMessage(undefined)
  }

  const applyCode = () => checkPromoCode({ code, planId: Number(id), price: order.paymentPrice })

  const useCode = () => {
    if (!checkPromoCodeResult.data) return

    dispatch(
      setPromo({
        promoCode: checkPromoCodeResult.data.promoCode,
        promoAmount: checkPromoCodeResult.data.value
      })
    )

    navigateBack()
  }

  return (
    <Layout
      title={L10N.LABEL.ENTER_PROMO_CODE}
      type={LayoutType.HeaderFooter}
      footerComponent={
        <EnterPromoFooter
          total={checkPromoCodeResult.data?.fee ?? 0}
          canSubmit={Boolean(checkPromoCodeResult.data?.promoId)}
          onSubmit={useCode}
        />
      }
    >
      <div className='animate-fadeIn'>
        <div className='mb-4 font-medium'>{L10N.LABEL.ENTER_PROMO_CODE}</div>

        <div className='flex flex-row gap-3 items-center mb-2'>
          <div className='flex-1'>
            <Input
              placeholder={L10N.LABEL.ENTER_PROMO_CODE}
              value={code}
              icon='coupon'
              actionIcon={code && 'close-filled'}
              onChange={inputCode}
              onAction={resetCode}
            />
          </div>
          <Button
            label={L10N.LABEL.APPLY}
            color={ButtonColor.Primary}
            disabled={!code}
            onClick={applyCode}
          />
        </div>

        {message && <div className={`line-clamp-2 text-sm ${messageColor}`}>{message}</div>}
      </div>
    </Layout>
  )
}

const getSuccessMessage = (data: CheckPromoCodeResponse) => {
  const messageTemplate =
    data.isPercentage && data.maximumDeduction > 0
      ? L10N.MESSAGE.PROMO_CODE_APPLIED_UP_TO_DESC
      : L10N.MESSAGE.PROMO_CODE_APPLIED_DESC

  const values = data.isPercentage
    ? [
        <span key='value-1' className='font-medium'>
          {data.valuePercentage}%
        </span>,
        data.maximumDeduction > 0 ? (
          <span key='value-2' className='font-medium'>
            {formatCurrency(data.maximumDeduction)}
          </span>
        ) : undefined
      ].filter(Boolean)
    : [
        <span key='value-1' className='font-medium'>
          {formatCurrency(data.fee)}
        </span>
      ]

  return (
    <>
      {messageTemplate.split(/{\d+}/).map((message, id, array) => {
        let output = <span key={id + 1}>{message}</span>
        if (id < array.length - 1)
          output = (
            <Fragment key={id + 1}>
              {output} {values[id]}
            </Fragment>
          )

        return output
      })}
    </>
  )
}
