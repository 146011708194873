import type { FetchArgs } from '@reduxjs/toolkit/query'
import { API } from 'global/api'
import { CONSTANT } from 'global/constant'
import { CopyrightType } from 'global/types/enums'
import type {
  CopyrightContentResponse,
  HelpItemResponse,
  HelpResponse,
  LocalizationResponse,
  PaginatedResponse
} from 'global/types/responses'
import { api, headers } from './api'

const getSingleCopyrightQuery = (type: CopyrightType): FetchArgs => {
  const params = {
    start: 0,
    length: 10
  }

  return {
    url: API.COPYRIGHT_URL.replace('{type}', CopyrightType[type]),
    params,
    method: 'GET',
    headers: headers({ useContentType: false })
  }
}

const transformResponse = (result: PaginatedResponse<CopyrightContentResponse>) => result.data?.[0]

const copyrightApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getLocalization: builder.query<LocalizationResponse, void>({
      query: () => ({
        url: API.LOCALIZATION_URL,
        method: 'GET',
        headers: headers()
      })
    }),
    getHelp: builder.query<HelpResponse, void>({
      query: () => ({
        url: API.COPYRIGHT_URL.replace('{type}', 'HELP'),
        method: 'GET',
        headers: headers()
      }),
      transformResponse: (result: PaginatedResponse<CopyrightContentResponse>) => {
        const image =
          result.data.find((d) => d.name === 'HELP' && d.code === 'BANNER')?.content ?? ''
        const title =
          result.data.find((d) => d.name === 'HELP' && d.code === 'TITLE')?.content ?? ''

        const keys = ['CONTACT', 'GOV_CONTACT', 'SOCMED'] as const
        const keyContents = []

        for (const key of keys) {
          const contactItems = result.data.filter((d) => d.name === key)
          contactItems.sort((a, b) => a.code.localeCompare(b.code))

          const parsedTitle = result.data
            .filter((d) => d.name === 'HELP' && d.code.startsWith(`${key}_TITLE`))
            .map((t) => t.content)
          parsedTitle.sort((a, b) => a.localeCompare(b))

          const parsedItems: HelpItemResponse[] = []
          for (const item of contactItems) {
            const [field, id] = item.code.split('_')

            const index = Number(id) - 1
            if (parsedItems[index]) {
              parsedItems[index] = { ...parsedItems[index], [field.toLowerCase()]: item.content }
            } else {
              parsedItems.push({
                icon: '',
                name: '',
                value: '',
                [field.toLowerCase()]: item.content
              })
            }
          }

          keyContents.push({
            title: parsedTitle.length === 1 ? parsedTitle[0] : parsedTitle,
            items: parsedItems
          })
        }

        return {
          image,
          title,
          contact: keyContents[0],
          govContact: keyContents[1],
          socmed: keyContents[2]
        } as HelpResponse
      }
    }),
    getTnc: builder.query<CopyrightContentResponse, void>({
      query: () => getSingleCopyrightQuery(CopyrightType.TNC),
      providesTags: [CONSTANT.LANG_TAG],
      transformResponse
    }),
    getPrivacyPolicy: builder.query<CopyrightContentResponse, void>({
      query: () => getSingleCopyrightQuery(CopyrightType.PRIVACY_POLICY),
      providesTags: [CONSTANT.LANG_TAG],
      transformResponse
    })
  })
})

export const {
  useGetLocalizationQuery,
  useGetHelpQuery,
  useGetTncQuery,
  useGetPrivacyPolicyQuery
} = copyrightApi
