import { L10N } from 'global/localization'
import type { EmptyProps } from 'global/types/props'
import { ResultContent } from 'shared/components/result-content'

export const Empty = ({ small = false, onAction }: EmptyProps) => {
  if (small) {
    return (
      <div className='flex flex-col items-center justify-center min-h-16'>
        <ResultContent
          // image='/not-found.png'
          title={L10N.LABEL.DATA_NOT_FOUND}
          actionText={L10N.LABEL.RELOAD}
          onAction={onAction}
        />
      </div>
    )
  }

  return (
    <div className='h-full min-h-60 flex flex-col items-center justify-center'>
      <ResultContent
        image='/not-found.png'
        title={L10N.LABEL.DATA_NOT_FOUND}
        actionText={L10N.LABEL.RELOAD}
        onAction={onAction}
      />
    </div>
  )
}
