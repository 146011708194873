import { useRef, useState } from 'react'
import type { InputProps } from 'global/types/props'
import Icon from 'shared/components/icon'

export const Input = ({
  value = '',
  placeholder = '',
  icon,
  actionIcon,
  onChange,
  onAction
}: InputProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const [focus, setFocus] = useState<boolean>(false)

  return (
    <div
      className={[
        'flex flex-row items-center justify-center bg-white border-b-2 border-white',
        focus ? 'border-b-primary' : ''
      ].join(' ')}
    >
      {icon && <Icon icon={icon} size={20} className='mx-3' />}

      <input
        ref={inputRef}
        className='flex-1 w-full h-12 bg-transparent focus:outline-none'
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        onChange={(e) => onChange?.(e.target.value)}
        placeholder={placeholder}
        value={value}
        type='text'
      />

      {actionIcon && (
        <button
          className='p-3'
          type='button'
          onClick={() => {
            inputRef.current?.focus()
            onAction?.()
          }}
        >
          <Icon icon={actionIcon} size={20} className='text-danger' />
        </button>
      )}
    </div>
  )
}
