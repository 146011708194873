import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CONSTANT } from 'global/constant'
import { L10N } from 'global/localization'
import type { DdlResponse } from 'global/types/responses'
import { type SubscriptionPackageResponse } from 'global/types/responses'
import { useAppDispatch } from 'store/store'
import {
  useGetActiveCarQuery,
  useGetPackageListQuery,
  useGetPeriodListQuery
} from 'store/subscription'
import { resetOrder } from 'store/slices/order'
import { DataHandler } from 'shared/components/data-handler'
import { Layout } from 'shared/components/layout'
import { SelectPlanContent } from './content'

export const SelectPlan = () => {
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const activeCarResult = useGetActiveCarQuery(localStorage.getItem(CONSTANT.VIN_KEY) ?? '')

  const packageListResult = useGetPackageListQuery(localStorage.getItem(CONSTANT.VIN_KEY) ?? '')

  const periodListResult = useGetPeriodListQuery()

  const [periodList, setPeriodList] = useState<DdlResponse[]>([])

  const [period, setPeriod] = useState<string>('')

  useEffect(() => {
    if (!periodListResult.isSuccess) return

    initPeriodList()
  }, [periodListResult.isSuccess])

  useEffect(() => {
    if (!packageListResult.isSuccess) return

    initPeriodList()
  }, [packageListResult.isSuccess])

  const initPeriodList = () => {
    if (!periodListResult.data?.length || !packageListResult.data?.length) return

    const periodList = parsePeriodList(periodListResult.data, packageListResult.data)

    setPeriodList(periodList)

    if (periodList.length) setPeriod(periodList[0].value)
  }

  const selectSubsPackage = (subsPackage: SubscriptionPackageResponse) => {
    if (!subsPackage.id) return

    dispatch(resetOrder())
    navigate(`order/${subsPackage.id}`)
  }

  return (
    <Layout title={L10N.LABEL.RENEW_PLAN}>
      <DataHandler
        isLoading={
          activeCarResult.isLoading || packageListResult.isLoading || periodListResult.isLoading
        }
        error={activeCarResult.error || packageListResult.error || periodListResult.error}
      >
        {activeCarResult.data && (
          <SelectPlanContent
            car={activeCarResult.data}
            isLoading={packageListResult.isLoading}
            subsPackageList={packageListResult.data}
            periodList={periodList}
            period={period}
            setPeriod={setPeriod}
            onSelectSubscriptionPackage={selectSubsPackage}
          />
        )}
      </DataHandler>
    </Layout>
  )
}

const parsePeriodList = (
  periodList: DdlResponse[],
  packageList: SubscriptionPackageResponse[]
): DdlResponse[] =>
  periodList.filter(
    (period) =>
      packageList.filter((p) => p.duration.toLowerCase() === period.value.toLowerCase()).length > 0
  )
