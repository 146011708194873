import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { CONSTANT } from 'global/constant'
import { L10N } from 'global/localization'
import { LayoutType, SubscriptionStatus } from 'global/types/enums'
import type { CarListItemResponse } from 'global/types/responses'
import { useLazyGetCarListQuery } from 'store/vehicles'
import { DataHandler } from 'shared/components/data-handler'
import { Layout } from 'shared/components/layout'
import { Empty } from 'shared/components/empty'
import { CarCard } from 'shared/components/car-card'

export const MyCar = () => {
  const navigate = useNavigate()

  const [getCarList, getCarListResult] = useLazyGetCarListQuery()

  useEffect(() => {
    getCarList()
  }, [])

  const openHomePage = (car: CarListItemResponse) => {
    localStorage.setItem(CONSTANT.VIN_KEY, car.vin)

    navigate('/home')
  }

  const viewHistory = (car: CarListItemResponse) => {
    navigate(`/transaction-history?vin=${car.vin}`)
  }

  return (
    <Layout title={L10N.LABEL.MY_CAR} type={LayoutType.TabFooter}>
      <DataHandler {...getCarListResult}>
        {getCarListResult.data?.data.length ? (
          getCarListResult.data.data.map((car, id) => (
            <CarCard
              key={id + 1}
              type='car-list-item'
              car={car}
              onDetail={
                car.subscriptionStatusActivation !== SubscriptionStatus.Inactive
                  ? openHomePage
                  : undefined
              }
              onHistory={
                car.subscriptionStatusActivation !== SubscriptionStatus.Inactive
                  ? viewHistory
                  : undefined
              }
              onSubscribe={
                car.subscriptionStatusActivation === SubscriptionStatus.Inactive
                  ? openHomePage
                  : undefined
              }
            />
          ))
        ) : (
          <Empty />
        )}
      </DataHandler>
    </Layout>
  )
}
