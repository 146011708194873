import { API } from 'global/api'
import { api, headers } from './api'

const blobApi = api.injectEndpoints({
  endpoints: (builder) => ({
    downloadFile: builder.query<string, string>({
      query: (fileUrl) => ({
        url: API.DOWNLOAD_URL,
        method: 'GET',
        headers: headers({ useContentType: false }),
        params: { fileUrl },
        responseHandler: async (response: Response) => {
          const contentType = response.headers.get('Content-Type')
          if (response.status !== 200 || contentType === 'application/json')
            return await response.json()

          const blob = await response.blob()
          return { data: URL.createObjectURL(blob) }
        }
      })
    })
  })
})

export const { useLazyDownloadFileQuery } = blobApi
