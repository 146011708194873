import { useState } from 'react'
import { useSelector } from 'react-redux'
import { CheckboxType } from 'global/types/enums'
import type { OrderDetailContentProps } from 'global/types/props'
import { useAppDispatch } from 'store/store'
import {
  getOrderState,
  setAgreePrivacyPolicy,
  setAgreeTnc,
  setEnableAutoRenewal
} from 'store/slices/order'
import { CarCard } from 'shared/components/car-card'
import { Card } from 'shared/components/card'
import { Checkbox } from 'shared/components/checkbox'
import { ConfirmModal } from 'shared/components/confirm-modal'
import Icon from 'shared/components/icon'
import { formatCurrency, formatDate } from 'shared/services/utils'
import { Empty } from 'shared/components/empty'
import { Spinner } from 'shared/components/spinner'
import { L10N } from 'global/localization'

export const OrderDetailContent = ({
  car,
  isLoading = false,
  orderDetail,
  onEnterPromo,
  onClickTnc,
  onClickPolicy
}: OrderDetailContentProps) => {
  const dispatch = useAppDispatch()

  const order = useSelector(getOrderState)

  const [showAutoRenewalModal, setShowAutoRenewalModal] = useState<boolean>(false)

  const toggleAutoRenewal = () => {
    const newValue = !order.isEnableAutoRenewal

    dispatch(setEnableAutoRenewal(newValue))
  }

  const toggleAgreeTnc = () => {
    const newValue = !order.isAgreeTnc

    if (newValue && !orderDetail?.hasReadAgreement) {
      onClickTnc?.(true)
      return
    }

    dispatch(setAgreeTnc(newValue))
  }

  const toggleAgreePrivacyPolicy = () => {
    const newValue = !order.isAgreePrivacyPolicy

    if (newValue && !orderDetail?.hasReadAgreement) {
      onClickPolicy?.(true)
      return
    }

    dispatch(setAgreePrivacyPolicy(newValue))
  }

  return (
    <>
      <CarCard type='active-car' car={car} hideSubscription hideAutoRenewal />

      {isLoading ? (
        <div className='flex items-center justify-center'>
          <Spinner />
        </div>
      ) : (
        <>
          {orderDetail ? (
            <>
              <h2 className='font-heading font-medium mb-4'>{L10N.LABEL.PURCHASE_DETAIL}</h2>

              <Card>
                <div className='flex flex-row items-center mb-4'>
                  <img
                    src={orderDetail.iconUrl || 'https://dummyimage.com/32'}
                    alt='subscription-package'
                    className='size-11 rounded-full mr-4'
                  />
                  <div className='flex flex-col'>
                    <div className='text-sm'>{L10N.LABEL.PLAN}</div>
                    <div className='font-medium text-sm'>
                      {orderDetail.name} - {orderDetail.durationDescription}
                    </div>
                  </div>
                </div>

                <div className='flex flex-row items-center justify-between'>
                  <div className='text-sm text-neutral'>{L10N.LABEL.SUBSCRIPTION_END_DATE}</div>
                  <div className='font-medium text-sm text-right'>
                    {formatDate(orderDetail.estimateServiceEndDate)}
                  </div>
                </div>
              </Card>

              <Card onClick={onEnterPromo}>
                <div className='flex flex-row items-center justify-between'>
                  <div className='flex flex-row items-center'>
                    <Icon icon='electric-car' size={24} className='mr-2' />
                    <div>
                      {order.promoCode
                        ? `${L10N.LABEL.PROMO_CODE}: ${order.promoCode}`
                        : L10N.LABEL.ENTER_PROMO_CODE}
                    </div>
                  </div>
                  <Icon icon={order.promoCode ? 'chevron-right' : 'add'} size={24} />
                </div>
              </Card>

              <h2 className='font-heading font-medium mb-4'>{L10N.LABEL.PAYMENT_DETAIL}</h2>

              <Card>
                <div className='flex flex-row items-center justify-between mb-3'>
                  <div className='text-sm'>{L10N.LABEL.PRICE}</div>
                  <div className='font-medium text-sm text-right'>
                    {formatCurrency(orderDetail.paymentFee.price)}
                  </div>
                </div>

                <div className='flex flex-row items-center justify-between mb-3'>
                  <div className='text-sm'>{L10N.LABEL.DISCOUNT}</div>
                  <div className='font-medium text-sm text-right'>
                    {(orderDetail.paymentFee.discount ?? 0) > 0 && '-'}
                    {formatCurrency(orderDetail.paymentFee.discount ?? 0)}
                  </div>
                </div>

                {orderDetail.paymentFee.promo && (
                  <div className='flex flex-row items-center justify-between mb-3'>
                    <div className='text-sm'>{L10N.LABEL.PROMO_CODE}</div>
                    <div className='font-medium text-sm text-right'>
                      {orderDetail.paymentFee.promo > 0 && '-'}
                      {formatCurrency(orderDetail.paymentFee.promo)}
                    </div>
                  </div>
                )}

                <div className='flex flex-row items-center justify-between mb-3'>
                  <div className='text-sm'>{L10N.LABEL.VAT}</div>
                  <div className='font-medium text-sm text-right'>
                    {formatCurrency(orderDetail.paymentFee.vat ?? 0)}
                  </div>
                </div>

                <hr className='border border-border mb-3' />

                <div className='flex flex-row items-center justify-between'>
                  <div className='font-bold text-sm'>{L10N.LABEL.TOTAL_INCLUDING_TAX}</div>
                  <div className='font-bold text-sm text-right'>
                    {formatCurrency(orderDetail.paymentFee.total)}
                  </div>
                </div>
              </Card>

              {orderDetail.autoRenewal && (
                <Card>
                  <div className='flex flex-row gap-2 items-center'>
                    <Icon icon='calendar-check' size={24} />

                    <div className='flex-1'>
                      <h3 className='text-sm'>{L10N.LABEL.AUTO_RENEWAL}</h3>
                      <div className='text-neutral text-xs'>
                        {L10N.formatString(
                          L10N.MESSAGE.AUTO_RENEWAL_DESC,
                          formatDate(orderDetail.estimateServiceEndDate),
                          orderDetail.duration.toLowerCase() === 'monthly'
                            ? L10N.LABEL.MONTH.toLowerCase()
                            : L10N.LABEL.YEAR.toLowerCase()
                        )}
                      </div>
                    </div>

                    <Checkbox
                      type={CheckboxType.Switch}
                      checked={order.isEnableAutoRenewal}
                      onCheck={() =>
                        !order.isEnableAutoRenewal
                          ? setShowAutoRenewalModal(true)
                          : toggleAutoRenewal()
                      }
                    />
                  </div>
                </Card>
              )}

              <div className='flex flex-row items-center gap-2 mb-4 text-sm'>
                <Checkbox checked={order.isAgreeTnc} onCheck={toggleAgreeTnc} />
                <div>
                  {L10N.LABEL.I_AGREE_TO}{' '}
                  <button
                    className='text-left text-secondary'
                    type='button'
                    onClick={() => onClickTnc?.(true)}
                  >
                    {L10N.LABEL.TERMS_CONDITIONS}
                  </button>
                </div>
              </div>

              <div className='flex flex-row items-center gap-2 text-sm'>
                <Checkbox checked={order.isAgreePrivacyPolicy} onCheck={toggleAgreePrivacyPolicy} />
                <div>
                  {L10N.LABEL.I_AGREE_TO}{' '}
                  <button
                    className='text-left text-secondary'
                    type='button'
                    onClick={() => onClickPolicy?.(true)}
                  >
                    {L10N.LABEL.PRIVACY_POLICY}
                  </button>
                </div>
              </div>

              <ConfirmModal
                title={L10N.LABEL.AUTO_RENEWAL_CONFIRMATION}
                desc={
                  <>
                    <div className='mb-3'>
                      {L10N.formatString(
                        L10N.MESSAGE.AUTO_RENEWAL_DESC,
                        formatDate(orderDetail.estimateServiceEndDate),
                        orderDetail.duration.toLowerCase() === 'monthly'
                          ? L10N.LABEL.MONTH.toLowerCase()
                          : L10N.LABEL.YEAR.toLowerCase()
                      )}{' '}
                      {L10N.MESSAGE.PRICE_CHANGE_DESC}
                    </div>
                    <button
                      className='text-secondary mb-3'
                      type='button'
                      onClick={() => onClickTnc?.(false)}
                    >
                      {L10N.LABEL.VIEW} {L10N.LABEL.TERMS_CONDITIONS}
                    </button>
                    <div>{L10N.MESSAGE.APPLY_PROMO_CODE_DESC}</div>
                  </>
                }
                open={showAutoRenewalModal}
                confirmText={L10N.LABEL.YES_ACTIVATE}
                onCancel={() => setShowAutoRenewalModal(false)}
                onConfirm={() => {
                  setShowAutoRenewalModal(false)
                  toggleAutoRenewal()
                }}
              />
            </>
          ) : (
            <Empty small />
          )}
        </>
      )}
    </>
  )
}
