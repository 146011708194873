import { API } from 'global/api'
import type { CheckPromoCodeParam } from 'global/types/params'
import type { CheckPromoCodeResponse } from 'global/types/responses'
import { api, headers } from './api'

const promoCodeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    checkPromoCode: builder.mutation<CheckPromoCodeResponse, CheckPromoCodeParam>({
      query: (param) => {
        const params = {
          planId: param.planId,
          price: param.price
        }

        return {
          url: API.CHECK_PROMO_CODE_URL.replace('{code}', param.code),
          params,
          method: 'GET',
          headers: headers()
        }
      }
    })
  })
})

export const { useCheckPromoCodeMutation } = promoCodeApi
