import { useEffect, useRef, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import type { PdfViewerProps } from 'global/types/props'
import { useLazyDownloadFileQuery } from 'store/blob'
import { Spinner } from 'shared/components/spinner'
import { Empty } from 'shared/components/empty'
import { useDebounce } from 'shared/services/hooks'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'

export const PdfViewer = ({ url, onComplete }: PdfViewerProps) => {
  const [downloadFile, downloadFileResult] = useLazyDownloadFileQuery()

  const pdfContentRef = useRef<HTMLDivElement>(null)

  const [pageCount, setPageCount] = useState<number>(0)

  const [width, setWidth] = useState<number>(0)

  const debounceWidth = useDebounce(String(width))

  useEffect(() => {
    window.addEventListener('load', updateWidth)
    window.addEventListener('resize', updateWidth)

    return () => {
      window.removeEventListener('load', updateWidth)
      window.removeEventListener('resize', updateWidth)

      if (downloadFileResult.data) URL.revokeObjectURL(downloadFileResult.data)
    }
  }, [])

  useEffect(() => {
    if (!url) return

    downloadFile(url)
  }, [url])

  useEffect(() => {
    if (!downloadFileResult.data) return

    updateWidth()
  }, [downloadFileResult.isFetching])

  const updateWidth = () => {
    setWidth(pdfContentRef.current?.offsetWidth ?? 0)
  }

  return (
    <div ref={pdfContentRef} className='h-full'>
      {downloadFileResult.isLoading ? (
        <div className='flex items-center justify-center'>
          <Spinner />
        </div>
      ) : downloadFileResult.data ? (
        <>
          {Boolean(Number(debounceWidth)) && (
            <Document
              file={downloadFileResult.data}
              loading={
                <div className='flex items-center justify-center'>
                  <Spinner />
                </div>
              }
              onLoadSuccess={(pdf) => {
                onComplete?.()
                setPageCount(pdf.numPages)
              }}
            >
              {Array.from(Array(pageCount).keys()).map((id) => (
                <Page
                  key={id + 1}
                  width={Number(debounceWidth)}
                  pageNumber={id + 1}
                  className='mb-4 last:mb-0'
                  loading={
                    <div className='flex items-center justify-center' />
                  }
                  onClick={(e) => {
                    e.preventDefault()

                    // const link: string = e.target.href
                    // if (link) openExternalLink(link)
                  }}
                />
              ))}
            </Document>
          )}
        </>
      ) : (
        <Empty />
      )}
    </div>
  )
}

// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString()
