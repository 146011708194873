import type { StatusBadgeProps } from 'global/types/props'

export const StatusBadge = ({ label, value }: StatusBadgeProps) => (
  <div
    className={[
      'px-2 py-1 font-medium text-xs text-white text-center',
      colorVariants[value.toLowerCase()] ?? 'bg-light-neutral'
    ].join(' ')}
  >
    {label}
  </div>
)

const colorVariants: Record<string, string> = {
  paid: 'bg-secondary',
  expired: 'bg-danger',
  failed: 'bg-danger',
  cancel: 'bg-danger',
  pending: 'bg-warning'
}
