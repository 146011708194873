import type { Middleware } from '@reduxjs/toolkit'
import { isRejectedWithValue } from '@reduxjs/toolkit'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { toast } from 'react-toastify'
import { L10N } from 'global/localization'
import type { BaseErrorResponse } from 'global/types/responses'

export const errorToast: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const fallbackMessage = L10N.MESSAGE.FALLBACK_ERROR_MESSAGE

    const error = (action.payload as FetchBaseQueryError)?.data as BaseErrorResponse | undefined

    toast.dismiss()

    console.error('❌ Oops, we got an error!', action)
    toast.error(<span className='line-clamp-2'>{error?.errorMessage ?? fallbackMessage}</span>)
  }

  return next(action)
}
