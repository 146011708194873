import LocalizedStrings from 'react-localization'
import type { LocalizationProps } from './types/props'

export const L10N: LocalizationProps = new LocalizedStrings({
  'en-US': {
    LABEL: {},
    MESSAGE: {}
  },
  ID: {
    LABEL: {},
    MESSAGE: {}
  }
})
