import { useState } from 'react'
import type { SubscriptionPackageProps } from 'global/types/props'
import { L10N } from 'global/localization'
import { Card } from 'shared/components/card'
import { formatCurrency, formatDate } from 'shared/services/utils'
import Icon from 'shared/components/icon'
import { Badge } from 'shared/components/badge'

export const SubscriptionPackage = ({
  subsPackage,
  isActive = false,
  onSelect
}: SubscriptionPackageProps) => {
  const [showBenefits, setShowBenefits] = useState<boolean>(false)

  return (
    <Card useBgPattern>
      <div className='flex flex-row items-center justify-between mb-3'>
        <img
          src={subsPackage.iconUrl ?? 'https://dummyimage.com/32'}
          alt='subscription-package'
          className='size-11 rounded-full bg-light-neutral'
        />
        {!isActive && (
          <button
            className='self-stretch text-right'
            type='button'
            onClick={() => onSelect?.(subsPackage)}
          >
            {L10N.LABEL.CHOOSE}
            <Icon icon='chevron-right' size={20} />
          </button>
        )}
      </div>

      <div className='flex flex-row items-center justify-between mb-3'>
        <div className='flex flex-col'>
          <h3 className='font-heading font-medium text-lg'>{subsPackage.name ?? '-'}</h3>
          <div className='text-neutral text-xs'>{subsPackage.description}</div>
        </div>

        <div className='flex flex-col text-right min-w-32'>
          <div className='font-heading font-medium text-secondary'>
            {subsPackage.priceAfterDiscount > 0
              ? formatCurrency(subsPackage.priceAfterDiscount)
              : L10N.LABEL.FREE}
          </div>

          {isActive ? (
            subsPackage.expiredDate && (
              <div className='flex flex-row items-center justify-end text-xs'>
                {[L10N.LABEL.UNTIL, formatDate(subsPackage.expiredDate)].join(' ')}
              </div>
            )
          ) : subsPackage.priceAfterDiscount > 0 &&
            subsPackage.price > 0 &&
            subsPackage.price !== subsPackage.priceAfterDiscount ? (
            <div className='flex flex-row items-center justify-end text-xs'>
              <span className='line-through'>{formatCurrency(subsPackage.price)}</span>
              {subsPackage.discountPercentage > 0 && (
                <span className='ml-1'>
                  <Badge text={`${subsPackage.discountPercentage}%`} />
                </span>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      {(isActive || (!isActive && showBenefits)) && (
        <>
          <h4 className='font-heading font-medium mb-3'>{L10N.LABEL.BENEFITS}</h4>

          {subsPackage.benefitItems?.length ? (
            <ul className={`list-disc text-neutral text-xs ml-4 ${!isActive && 'mb-3'}`}>
              {subsPackage.benefitItems?.map((benefit, id) => <li key={id + 1}>{benefit}</li>)}
            </ul>
          ) : (
            <div className='text-neutral text-xs mb-3'>-</div>
          )}
        </>
      )}

      {!isActive && (
        <button className='text-sm' type='button' onClick={() => setShowBenefits(!showBenefits)}>
          <span className='mr-1'>
            {showBenefits ? L10N.LABEL.HIDE_BENEFITS : L10N.LABEL.LEARN_BENEFITS}
          </span>
          <Icon icon={showBenefits ? 'chevron-up' : 'chevron-down'} size={20} />
        </button>
      )}
    </Card>
  )
}
