import { L10N } from 'global/localization'
import { LayoutType } from 'global/types/enums'
import type { HelpItemResponse } from 'global/types/responses'
import { useGetHelpQuery } from 'store/copyright'
import { DataHandler } from 'shared/components/data-handler'
import { Layout } from 'shared/components/layout'
import Icon from 'shared/components/icon'
import { openExternalLink } from 'shared/services/utils'

export const Help = () => {
  const { data, isLoading, error } = useGetHelpQuery()

  return (
    <Layout title={L10N.LABEL.HELP_CENTER} type={LayoutType.TabFooter} noPadding>
      <DataHandler isLoading={isLoading} error={error}>
        {data && (
          <>
            <div className='bg-white mb-4'>
              <div className='w-full h-60'>
                <img src={data.image} alt='help-center' className='size-full object-cover' />
              </div>
              <h2 className='font-heading font-medium text-center p-4'>{data.title}</h2>
            </div>

            <div className='bg-white mb-4'>
              <h2 className='font-heading font-medium text-sm text-center p-4'>
                {data.contact.title}
              </h2>

              <div className='pl-4'>{getList(data.contact.items)}</div>
            </div>

            <div className='bg-white mb-4'>
              <div className='text-neutral text-sm text-center p-4'>
                {data.govContact.title.map((t, id) => (
                  <div key={id + 1}>{t}</div>
                ))}
              </div>

              <div className='pl-4'>{getList(data.govContact.items)}</div>
            </div>

            <div className='bg-white mb-4'>
              <h2 className='font-heading font-medium text-sm text-center p-4'>
                {data.socmed.title}
              </h2>

              <div className='grid grid-cols-4'>{getThumbnails(data.socmed.items)}</div>
            </div>
          </>
        )}
      </DataHandler>
    </Layout>
  )
}

const getList = (items: HelpItemResponse[]) => (
  <>
    {items.map((item, id) => (
      <button
        key={id + 1}
        className='flex flex-row gap-3 w-full text-left items-center pr-4 py-3 border-b border-border last:border-b-0'
        onClick={() => openExternalLink(getListItemLink(item))}
      >
        <Icon icon={item.icon} size={20} />
        <div className='flex-1'>
          <div className='text-neutral text-xs'>{item.name}</div>
          <div className='font-heading text-sm'>{item.value}</div>
        </div>
        <Icon icon='chevron-right' size={20} />
      </button>
    ))}
  </>
)

const getListItemLink = (item: HelpItemResponse): string => {
  switch (item.icon) {
    case 'phone':
      return `tel:${item.value.replaceAll(' ', '')}`
    case 'whatsapp':
      return `https://wa.me/${item.value.replaceAll(' ', '')}`
    case 'mail':
      return `mailto:${item.value}`
    default:
      return ''
  }
}

const getThumbnails = (items: HelpItemResponse[]) => (
  <>
    {items.map((item, id) => (
      <button
        key={id + 1}
        className='flex flex-col items-center mb-4'
        onClick={() => openExternalLink(item.value)}
      >
        <Icon icon={item.icon} size={32} className='mb-2' />
        <div className='text-neutral text-xs'>{item.name}</div>
      </button>
    ))}
  </>
)
