import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import { Layout } from 'shared/components/layout'
import { CONSTANT } from 'global/constant'
import { L10N } from 'global/localization'
import { LayoutType, ResultType, SubscriptionStatus } from 'global/types/enums'
import type { DdlResponse, SubscriptionPackageResponse } from 'global/types/responses'
import { useAppDispatch } from 'store/store'
import {
  useGetActiveCarQuery,
  useGetPeriodListQuery,
  useLazyGetActivePackageQuery,
  useLazyGetPackageListQuery,
  useStopAutoRenewalMutation
} from 'store/subscription'
import { resetOrder } from 'store/slices/order'
import { DataHandler } from 'shared/components/data-handler'
import { ConfirmModal } from 'shared/components/confirm-modal'
import { Empty } from 'shared/components/empty'
import { formatDate } from 'shared/services/utils'
import { SelectPlanContent } from 'pages/select-plan/content'
import { HomeContent } from './content'

export const Home = () => {
  const location = useLocation()

  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const activeCarResult = useGetActiveCarQuery(localStorage.getItem(CONSTANT.VIN_KEY) ?? '')

  const [getActivePackage, activePackageResult] = useLazyGetActivePackageQuery()

  const [getPackageList, packageListResult] = useLazyGetPackageListQuery()

  const [stopRenewalStatus, stopRenewalStatusResult] = useStopAutoRenewalMutation()

  const periodListResult = useGetPeriodListQuery()

  const [periodList, setPeriodList] = useState<DdlResponse[]>([])

  const [period, setPeriod] = useState<string>('')

  const [showConfirm, setShowConfirm] = useState<boolean>(false)

  useEffect(() => {
    if (!activeCarResult.data) return

    if (activeCarResult.data.statusActivation !== SubscriptionStatus.Inactive) {
      getActivePackage(localStorage.getItem(CONSTANT.VIN_KEY) ?? '')
    } else {
      getPackageList(localStorage.getItem(CONSTANT.VIN_KEY) ?? '')
    }
  }, [activeCarResult.data])

  useEffect(() => {
    if (!periodListResult.isSuccess) return

    initPeriodList()
  }, [periodListResult.isSuccess])

  useEffect(() => {
    if (!packageListResult.isSuccess) return

    initPeriodList()
  }, [packageListResult.isSuccess])

  const initPeriodList = () => {
    if (!periodListResult.data?.length || !packageListResult.data?.length) return

    const periodList = parsePeriodList(periodListResult.data, packageListResult.data)

    setPeriodList(periodList)

    if (periodList.length) setPeriod(periodList[0].value)
  }

  useEffect(() => {
    if (stopRenewalStatusResult.isSuccess) {
      if (!activeCarResult.data) return

      const qParams = {
        t: ResultType.AutoRenewalStop,
        d: JSON.stringify({ date: activeCarResult.data.expiredDate })
      }

      navigate(`/result?${queryString.stringify(qParams)}`)
    }
  }, [stopRenewalStatusResult.data])

  const selectSubsPackage = (subsPackage: SubscriptionPackageResponse) => {
    if (!subsPackage.id) return

    const urlSegments = location.pathname.split('/')
    const qParam = urlSegments[1] === 'home' ? 'from=home' : ''

    dispatch(resetOrder())
    navigate([`/subscription-plan/order/${subsPackage.id}`, qParam].filter(Boolean).join('?'))
  }

  const upgradePlan = () => {
    navigate('/subscription-plan')
  }

  const stopAutoRenewal = () => {
    if (!activeCarResult.data?.planId) return

    stopRenewalStatus({
      planId: activeCarResult.data.planId,
      vin: localStorage.getItem(CONSTANT.VIN_KEY) ?? ''
    })
  }

  return (
    <Layout title={L10N.LABEL.HOME} type={LayoutType.TabFooter}>
      <DataHandler {...activeCarResult}>
        {activeCarResult.data ? (
          <>
            {activeCarResult.data.statusActivation !== SubscriptionStatus.Inactive ? (
              <HomeContent
                car={activeCarResult.data}
                isLoading={activePackageResult.isLoading}
                activePackages={activePackageResult.data?.data}
                onUpgradePlan={upgradePlan}
                onStopAutoRenewal={() => setShowConfirm(true)}
              />
            ) : (
              <SelectPlanContent
                car={activeCarResult.data}
                isLoading={packageListResult.isLoading}
                subsPackageList={packageListResult.data}
                periodList={periodList}
                period={period}
                setPeriod={setPeriod}
                onSelectSubscriptionPackage={selectSubsPackage}
              />
            )}
          </>
        ) : (
          <Empty />
        )}
      </DataHandler>

      {activeCarResult.data && (
        <ConfirmModal
          title={L10N.LABEL.CONFIRMATION}
          desc={L10N.formatString(
            L10N.MESSAGE.STOP_RENEWAL_DESC,
            formatDate(activeCarResult.data.nextPaymentDate, true)
          )}
          confirmText={L10N.LABEL.YES_STOP}
          open={showConfirm}
          onCancel={() => setShowConfirm(false)}
          onConfirm={stopAutoRenewal}
        />
      )}
    </Layout>
  )
}

const parsePeriodList = (
  periodList: DdlResponse[],
  packageList: SubscriptionPackageResponse[]
): DdlResponse[] =>
  periodList.filter(
    (period) =>
      packageList.filter((p) => p.duration.toLowerCase() === period.value.toLowerCase()).length > 0
  )
