import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { L10N } from 'global/localization'
import type { HeaderProps } from 'global/types/props'
import { getLayoutState } from 'store/slices/layout'
import Icon from 'shared/components/icon'

export const Header = ({
  title,
  backIcon = 'arrow-left',
  onBack,
  actionIcon,
  onAction,
  search = false,
  onSearch
}: HeaderProps) => {
  const navigate = useNavigate()

  const { hasFilter } = useSelector(getLayoutState)

  const getContent = () => {
    if (search)
      return (
        <div className='flex flex-row items-center w-full mx-4 bg-background'>
          <Icon icon='search' size={20} className='mx-3' />

          <input
            className='flex-1 h-10 bg-transparent focus:outline-none'
            placeholder={L10N.LABEL.SEARCH}
            onChange={(e) => onSearch?.(e.target.value)}
            type='search'
          />

          {actionIcon && onAction && (
            <button className='px-3 py-2' type='button' onClick={onAction}>
              <div className='relative'>
                <Icon icon={actionIcon} size={20} />
                {hasFilter && (
                  <div className='absolute translate-x-1/2 -translate-y-1/2 top-0 right-0 bg-primary size-2 rounded-full' />
                )}
              </div>
            </button>
          )}
        </div>
      )

    return (
      <>
        <button className='p-4' type='button' onClick={onBack ?? (() => navigate(-1))}>
          <Icon icon={backIcon} size={20} />
        </button>

        <h2 className='flex-1 font-heading font-medium text-lg text-center line-clamp-1'>
          {title}
        </h2>

        {actionIcon && onAction ? (
          <button className='p-4' type='button' onClick={onAction}>
            <Icon icon={actionIcon} size={20} />
          </button>
        ) : (
          <div className='p-4'>
            <div className='size-5' />
          </div>
        )}
      </>
    )
  }

  return (
    <div className='pb-14'>
      <header className='fixed bg-white w-full h-14 z-10 flex items-center justify-between overflow-hidden'>
        {getContent()}
      </header>
    </div>
  )
}
