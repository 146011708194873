import type { SelectPlanContentProps } from 'global/types/props'
import { PillNav } from 'shared/components/pill-nav'
import { SubscriptionPackage } from 'shared/components/subscription-package'
import { CarCard } from 'shared/components/car-card'
import { Spinner } from 'shared/components/spinner'
import { Empty } from 'shared/components/empty'
import { L10N } from 'global/localization'
import type { SubscriptionPackageResponse } from 'global/types/responses'

export const SelectPlanContent = ({
  car,
  isLoading,
  subsPackageList,
  periodList,
  period,
  setPeriod,
  onSelectSubscriptionPackage
}: SelectPlanContentProps) => (
  <>
    <CarCard type='active-car' car={car} />

    {isLoading ? (
      <div className='flex items-center justify-center'>
        <Spinner />
      </div>
    ) : subsPackageList?.length ? (
      <>
        <div className='mb-4'>
          <h2 className='font-heading font-medium text-lg text-center'>{L10N.LABEL.CHOOSE_PLAN}</h2>

          <div className='text-neutral text-sm text-center'>
            {L10N.LABEL.UNLOCK_BLUELINK_FEATURES}
          </div>
        </div>

        {periodList?.length && (
          <div className='text-center mb-4'>
            <PillNav
              items={periodList.map((p) => ({ key: p.value, value: p.text }))}
              selected={period}
              onSelect={setPeriod}
            />
          </div>
        )}

        {filterPackageList(subsPackageList, period).length ? (
          filterPackageList(subsPackageList, period).map((subsPackage, id) => (
            <SubscriptionPackage
              key={id + 1}
              subsPackage={subsPackage}
              onSelect={onSelectSubscriptionPackage}
            />
          ))
        ) : (
          <Empty small />
        )}
      </>
    ) : (
      <Empty small />
    )}
  </>
)

const filterPackageList = (packageList: SubscriptionPackageResponse[], period: string) =>
  packageList.filter((p) => p.duration.toLowerCase() === period.toLowerCase())
