import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { LocationLog, LocationLogsState } from 'global/types/states'
import type { RootState } from 'store/store'

const initialState: LocationLogsState = {
  logs: []
}

const locationLogsSlice = createSlice({
  name: 'locationLogs',
  initialState,
  reducers: {
    addLocationLog: (state, action: PayloadAction<LocationLog>) => {
      state.logs = [action.payload, ...state.logs]
    }
  }
})

export const { addLocationLog } = locationLogsSlice.actions

export const getLocationLogsState = (state: RootState) => state.locationLogs

export const locationLogsReducer = locationLogsSlice.reducer
