import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { CONSTANT } from 'global/constant'
import { L10N } from 'global/localization'
import { LayoutType } from 'global/types/enums'
import type { TrxHistoryParam } from 'global/types/params'
import type { TrxFilterValuesProps } from 'global/types/props'
import type { DdlResponse, TrxHistoryListItemResponse } from 'global/types/responses'
import { useAppDispatch } from 'store/store'
import { useGetTrxStatusListQuery, useLazyGetTrxHistoryQuery } from 'store/subscription'
import { getLayoutState, setHasFilter } from 'store/slices/layout'
import { Layout } from 'shared/components/layout'
import { DataHandler } from 'shared/components/data-handler'
import { useDebounce } from 'shared/services/hooks'
import { ResultContent } from 'shared/components/result-content'
import { Spinner } from 'shared/components/spinner'
import { TrxHistoryFilter } from './filter'
import { TrxHistoryListItem } from './list-item'

const VIN_QPARAM_KEY = 'vin'

export const TrxHistory = () => {
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const [searchParams] = useSearchParams()

  const { contentHeight } = useSelector(getLayoutState)

  const getTrxStatusListResult = useGetTrxStatusListQuery()

  const [getTrxHistory, trxHistoryResult] = useLazyGetTrxHistoryQuery()

  const [history, setHistory] = useState<(TrxHistoryListItemResponse & { statusLabel: string })[]>(
    []
  )

  const [showFilter, setShowFilter] = useState<boolean>(false)

  const [filterValues, setFilterValues] = useState<TrxFilterValuesProps>(getFilterDefaultValues())

  const [historyFilter, setHistoryFilter] = useState<TrxHistoryParam>({
    start: 0,
    search: '',
    vin: searchParams.get(VIN_QPARAM_KEY) ?? undefined
  })

  const [searchKey, setSearchKey] = useState<string>()

  const debounceSearchKey = useDebounce(searchKey)

  useEffect(() => {
    if (debounceSearchKey === undefined) return

    setHistoryFilter((f) => ({ ...f, start: 0, search: debounceSearchKey ?? '' }))
  }, [debounceSearchKey])

  useEffect(() => {
    if (!getTrxStatusListResult.isSuccess || !getTrxStatusListResult.data?.length) return

    getHistory()
  }, [getTrxStatusListResult.data?.length])

  useEffect(() => {
    dispatch(setHasFilter(hasFilter(historyFilter)))
    getHistory()
  }, [historyFilter, getTrxHistory])

  const getHistory = () => {
    if (!getTrxStatusListResult.data?.length) return

    getTrxHistory(historyFilter)
      .unwrap()
      .then((res) => {
        toast.dismiss()

        const parsedData = parseData(res.data, getTrxStatusListResult.data ?? [])

        setHistory((prevHistory) =>
          historyFilter.start > 0 ? [...prevHistory, ...parsedData] : parsedData
        )
      })
      .catch(console.error)
  }

  const searchHistory = (keyword: string) => {
    setSearchKey(keyword)
  }

  const applyFilter = (filter: TrxFilterValuesProps) => {
    setShowFilter(false)

    setFilterValues(filter)

    setHistoryFilter((f) => ({
      ...f,
      vin: undefined,
      start: 0,
      status: filter.paymentStatus,
      planId: filter.subscriptionPlan,
      paymentMethod: filter.paymentMethod,
      transactionStartDate: filter.transactionDate?.[0]
        ? moment(filter.transactionDate[0]).format('YYYY-MM-DD')
        : undefined,
      transactionEndDate: filter.transactionDate?.[1]
        ? moment(filter.transactionDate[1]).format('YYYY-MM-DD')
        : undefined,
      sort: filter.sort ? getSortValue(filter.sort) : undefined
    }))
  }

  const openDetailPage = (id: number) => {
    navigate(`${id}`)
  }

  return (
    <Layout
      title={L10N.LABEL.TRANSACTION_HISTORY}
      type={LayoutType.SearchHeaderWithTabFooter}
      noPadding
      onSearch={searchHistory}
      actionIcon='tune'
      onAction={() => setShowFilter(true)}
    >
      <DataHandler
        isLoading={
          getTrxStatusListResult.isLoading ||
          (historyFilter.start === 0 && trxHistoryResult.isFetching)
        }
        error={getTrxStatusListResult.error || trxHistoryResult.error}
      >
        {history.length ? (
          <InfiniteScroll
            dataLength={history.length}
            height={contentHeight}
            next={() => {
              setHistoryFilter((f) => ({ ...f, start: f.start + 1 }))
            }}
            hasMore={
              history.length < (trxHistoryResult.data?.recordsTotal ?? 0) &&
              (trxHistoryResult.data?.data.length ?? 0) === CONSTANT.ITEMS_PER_PAGE
            }
            loader={
              <div className='flex flex-row gap-2 items-center justify-center text-sm mb-4'>
                <Spinner />
                <div className='text-neutral'>{L10N.LABEL.LOADING}</div>
              </div>
            }
            endMessage={
              <div className='flex flex-row gap-2 items-center justify-center text-sm mb-4'>
                <div className='text-neutral'>{L10N.LABEL.NO_MORE_DATA}</div>
              </div>
            }
            refreshFunction={() => setHistoryFilter((f) => ({ ...f, start: 0 }))}
            pullDownToRefresh
            pullDownToRefreshThreshold={50}
            pullDownToRefreshContent={
              <div className='mb-4 text-center'>&#8595; {L10N.LABEL.PULL_TO_REFRESH}</div>
            }
            releaseToRefreshContent={
              <div className='mb-4 text-center'>&#8593; {L10N.LABEL.RELEASE_TO_REFRESH}</div>
            }
          >
            <div className='p-4'>
              {history.map((item, id) => (
                <TrxHistoryListItem key={id + 1} trxDetail={item} onClick={openDetailPage} />
              ))}
            </div>
          </InfiniteScroll>
        ) : historyFilter.search ? (
          <div className='h-full flex flex-col items-center justify-center p-4'>
            <ResultContent
              image='/search-not-found.png'
              title={L10N.LABEL.SEARCH_NOT_FOUND}
              desc={L10N.MESSAGE.SEARCH_NOT_FOUND_DESC}
            />
          </div>
        ) : (
          <div className='h-full flex flex-col items-center justify-center p-4'>
            <ResultContent
              image='/search-not-found.png'
              title={L10N.LABEL.TRANSACTION_NOT_FOUND}
              desc={L10N.MESSAGE.TRANSACTION_NOT_FOUND_DESC}
            />
          </div>
        )}
      </DataHandler>

      <TrxHistoryFilter
        open={showFilter}
        values={filterValues}
        onClose={() => setShowFilter(false)}
        onReset={applyFilter}
        onApply={applyFilter}
      />
    </Layout>
  )
}

export const getFilterDefaultValues = (): TrxFilterValuesProps => ({
  paymentStatus: undefined,
  subscriptionPlan: undefined,
  paymentMethod: undefined,
  transactionDate: undefined,
  sort: L10N.LABEL.NEWEST
})

const hasFilter = (appliedFilter: TrxHistoryParam): boolean => {
  const sortNewest = getSortValue(L10N.LABEL.NEWEST)

  const defaultSort: boolean =
    !appliedFilter.sort ||
    (Boolean(appliedFilter.sort) &&
      appliedFilter.sort?.field === sortNewest?.field &&
      appliedFilter.sort?.dir === sortNewest?.dir)

  return (
    Boolean(appliedFilter.vin) ||
    Boolean(appliedFilter.status?.length) ||
    Boolean(appliedFilter.planId) ||
    Boolean(appliedFilter.paymentMethod) ||
    (Boolean(appliedFilter.transactionStartDate) && Boolean(appliedFilter.transactionEndDate)) ||
    !defaultSort
  )
}

const parseData = (
  data: TrxHistoryListItemResponse[],
  statusList: DdlResponse[]
): (TrxHistoryListItemResponse & { statusLabel: string })[] =>
  data.map((d) => ({
    ...d,
    statusLabel: statusList.find((s) => s.value === d.status)?.text ?? d.status
  }))

const getSortValue = (filterValue: string): TrxHistoryParam['sort'] => {
  switch (filterValue) {
    case L10N.LABEL.OLDEST:
      return { field: 'paidDate', dir: 'ASC' }
    case L10N.LABEL.NEWEST:
      return { field: 'paidDate', dir: 'DESC' }
    case L10N.LABEL.ASCENDING:
      return { field: 'name', dir: 'ASC' }
    case L10N.LABEL.DESCENDING:
      return { field: 'name', dir: 'DESC' }
  }
}
