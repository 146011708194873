import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import queryString from 'query-string'
import { toast } from 'react-toastify'
import { L10N } from 'global/localization'
import { LayoutType, ResultType } from 'global/types/enums'
import type { DdlResponse, TrxHistoryDetailResponse } from 'global/types/responses'
import { useAppDispatch } from 'store/store'
import {
  useCancelOrderMutation,
  useLazyGetTrxHistoryDetailQuery,
  useGetTrxStatusListQuery,
  useLazyGetActiveCarQuery
} from 'store/subscription'
import { setPaymentUrl } from 'store/slices/payment'
import { CarCard } from 'shared/components/car-card'
import { DataHandler } from 'shared/components/data-handler'
import { Layout } from 'shared/components/layout'
import { Empty } from 'shared/components/empty'
import { ConfirmModal } from 'shared/components/confirm-modal'
import { openExternalLink } from 'shared/services/utils'
import { TrxHistoryDetailFooter } from './footer'
import { TrxHistoryDetailContent } from './content'

export const TrxHistoryDetail = () => {
  const { id } = useParams()

  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const [getActiveCar, getActiveCarResult] = useLazyGetActiveCarQuery()

  const [getTrxDetail, trxDetailResult] = useLazyGetTrxHistoryDetailQuery()

  const getTrxStatusListResult = useGetTrxStatusListQuery()

  const [cancelOrder, cancelOrderResult] = useCancelOrderMutation()

  const [trxDetail, setTrxDetail] = useState<TrxHistoryDetailResponse & { statusLabel: string }>()

  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)

  useEffect(() => {
    if (!id) return

    getTrxDetail(id)
  }, [id])

  useEffect(() => {
    if (!trxDetailResult.data || !getTrxStatusListResult.data) return

    getActiveCar(trxDetailResult.data.customerVehicle.vin)
    setTrxDetail(parseData(trxDetailResult.data, getTrxStatusListResult.data ?? []))
  }, [trxDetailResult.isFetching])

  useEffect(() => {
    if (!cancelOrderResult.isSuccess) return

    setShowConfirmation(false)
    toast.info(L10N.MESSAGE.SUCCESS_CANCEL_TRANSACTION)
  }, [cancelOrderResult.isSuccess])

  const openFailedPage = () => {
    const qParams = {
      t: ResultType.PaymentFailed
    }

    navigate(`/result?${queryString.stringify(qParams)}`)
  }

  const onAction = (actionLabel: string) => {
    if (!trxDetailResult.data) return

    switch (actionLabel) {
      case L10N.LABEL.DOWNLOAD_INVOICE:
        if (!trxDetailResult.data.invoiceUrl) {
          toast.error(L10N.LABEL.DATA_NOT_FOUND)
          return
        }

        openExternalLink(trxDetailResult.data.invoiceUrl, 'download')

        // if (invoiceUrl) return openExternalLink(invoiceUrl, 'download')

        // downloadInvoice(trxDetailResult.data.invoiceUrl)
        break

      case L10N.LABEL.GO_TO_PAYMENT:
        if (!trxDetailResult.data.xendit.invoiceAction.url) return

        dispatch(setPaymentUrl(trxDetailResult.data.xendit.invoiceAction.url))
        navigate(`/payment/${trxDetailResult.data.id}`)
        break

      case L10N.LABEL.CANCEL:
        setShowConfirmation(true)
        break
    }
  }

  const onCancelOrder = () => {
    if (!trxDetailResult.data) return

    cancelOrder({
      planId: trxDetailResult.data.planId,
      invoiceNo: trxDetailResult.data.invoiceNo
    })
  }

  return (
    <Layout
      title={L10N.LABEL.TRANSACTION_DETAIL}
      type={LayoutType.HeaderFooter}
      footerComponent={
        getActiveCarResult.data && trxDetailResult.data && getTrxStatusListResult.data ? (
          <TrxHistoryDetailFooter trxDetail={trxDetailResult.data} onClick={onAction} />
        ) : undefined
      }
    >
      <DataHandler
        isLoading={
          trxDetailResult.isLoading ||
          getActiveCarResult.isLoading ||
          getTrxStatusListResult.isLoading
        }
        error={trxDetailResult.error || getActiveCarResult.error || getTrxStatusListResult.error}
      >
        {trxDetail && getActiveCarResult.data && getTrxStatusListResult.data ? (
          <>
            <CarCard
              type='active-car'
              car={getActiveCarResult.data}
              hideSubscription
              hideAutoRenewal
            />
            <TrxHistoryDetailContent trxDetail={trxDetail} onExpired={openFailedPage} />
          </>
        ) : (
          <Empty />
        )}
      </DataHandler>

      {trxDetailResult.data && (
        <ConfirmModal
          title={L10N.LABEL.TRANSACTION_CONFIRMATION}
          desc={L10N.MESSAGE.CANCEL_TRANSACTION_DESC}
          confirmText={L10N.LABEL.YES_CANCEL}
          cancelText={L10N.LABEL.NO}
          open={showConfirmation}
          onCancel={() => setShowConfirmation(false)}
          onConfirm={onCancelOrder}
        />
      )}
    </Layout>
  )
}

const parseData = (
  data: TrxHistoryDetailResponse,
  statusList: DdlResponse[]
): TrxHistoryDetailResponse & { statusLabel: string } => ({
  ...data,
  statusLabel: statusList.find((s) => s.value === data.invoiceStatus)?.text ?? data.invoiceStatus
})
