export const CONSTANT = {
  BASE_URL: process.env.REACT_APP_BASE_URL,

  // Local storage keys.
  SESSION_ID_KEY: 'sessionId',
  /** Token key, generated from create session. */
  TOKEN_KEY: 'token',
  LANGUAGE_KEY: 'lang',
  VIN_KEY: 'vin',

  // Date picker keys.
  DATE_RANGE_SELECTION_KEY: 'selection',

  // Language values.
  ENUS_LOCALE: 'en-US',
  ID_LOCALE: 'ID',

  // Date time formats.
  SHORT_DATE_FORMAT: 'D MMM YYYY',
  LONG_DATE_FORMAT: 'D MMMM YYYY',
  SHORT_DATETIME_FORMAT: 'D MMM YYYY HH:mm',
  LONG_DATETIME_FORMAT: 'D MMM YYYY HH:mm:ss',
  MONTH_DFNS_FORMAT: 'MMMM yyyy',

  // Currency formats.
  ID_CURRENCY_FORMAT: 'Rp #',

  // Pagination properties.
  ITEMS_PER_PAGE: 10,

  // RTKQ data-caching tags.
  CAR_TAG: 'Car',
  LANG_TAG: 'Language',
  TRANSACTION_TAG: 'Transaction'
} as const
