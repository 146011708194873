/* eslint-disable @typescript-eslint/no-explicit-any */
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { store } from './store/store'

import './styles/index.css'
import './styles/toast.css'
import './styles/carousel.css'
import './styles/date-picker.css'
import './styles/modal.css'
import './styles/button.css'
import './styles/badge.css'
import './styles/dev-center.css'

const rootElement = document.getElementById('root') as HTMLElement

rootElement.setAttribute('data-version', process.env.REACT_APP_VERSION ?? '')

const root = ReactDOM.createRoot(rootElement)
root.render(
  <Provider store={store}>
    <App />
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

declare global {
  interface Window {
    ReactNativeWebView: {
      postMessage: (data: string) => void
    }

    Android: any

    webkit: any
  }

  // eslint-disable-next-line no-var
  var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined
}
