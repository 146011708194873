import { useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation, useSearchParams } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/id'
import { CONSTANT } from 'global/constant'
import { L10N } from 'global/localization'
import { AuthenticationStatus } from 'global/types/enums'
import type { SessionResponse } from 'global/types/responses'
import type { AuthState } from 'global/types/states'
import { useGetLocalizationQuery } from 'store/copyright'
import { useCreateSessionMutation } from 'store/session'
import { Spinner } from 'shared/components/spinner'
import { LocationLogger } from 'shared/components/location-logger'

const CODE_QPARAM_KEY = 'code'
const STATE_QPARAM_KEY = 'state'

export const Protected = () => {
  const location = useLocation()

  const { data: localization, isLoading, isSuccess, isError } = useGetLocalizationQuery()

  const [createSession, session] = useCreateSessionMutation()

  const [searchParams] = useSearchParams()

  const [authStatus, setAuthStatus] = useState<number>(AuthenticationStatus.Loading)

  useEffect(() => {
    if (!isSuccess) return

    L10N.setContent(localization)

    if (hasInitialQparams(searchParams)) {
      storeKeys(searchParams)

      const rawState = searchParams.get(STATE_QPARAM_KEY)
      if (!rawState) return setAuthStatus(AuthenticationStatus.NotAuthenticated)

      const state: AuthState = JSON.parse(rawState)

      const language = getSupportedLanguage(state.lang)

      L10N.setLanguage(language)
      moment.locale(language)

      // Create new session based on query parameters.
      createSession({
        authCode: searchParams.get(CODE_QPARAM_KEY) ?? '',
        language: state.lang,
        latitude: Number(state.lat),
        longitude: Number(state.lon)
      })
    } else {
      L10N.setLanguage(localStorage.getItem(CONSTANT.LANGUAGE_KEY) ?? '')
      moment.locale(localStorage.getItem(CONSTANT.LANGUAGE_KEY) ?? CONSTANT.ENUS_LOCALE)

      validateSession()
    }
  }, [localization, isSuccess])

  useEffect(() => {
    if (!localization) return

    validateSession()
  }, [location, searchParams])

  useEffect(() => {
    if (session.isSuccess && session.data) {
      validateSession(session.data)
    } else if (session.isError) {
      setAuthStatus(AuthenticationStatus.NotAuthenticated)
    }
  }, [session])

  const validateSession = (session?: SessionResponse) => {
    const token = session?.token ?? localStorage.getItem(CONSTANT.TOKEN_KEY)
    const sessionId = session?.sessionId ?? localStorage.getItem(CONSTANT.SESSION_ID_KEY)

    if (session) {
      if (token) localStorage.setItem(CONSTANT.TOKEN_KEY, token)
      if (sessionId) localStorage.setItem(CONSTANT.SESSION_ID_KEY, sessionId)
    }

    setAuthStatus(Number(Boolean(token) && Boolean(sessionId)))
  }

  if (isLoading) {
    return (
      <>
        <LocationLogger />
        <div className='flex items-center justify-center h-screen text-lg'>
          <div className='flex flex-row gap-1 items-center'>
            <Spinner />
            <span>Loading...</span>
          </div>
        </div>
      </>
    )
  } else if (isError) {
    return (
      <>
        <LocationLogger />
        <div className='flex items-center justify-center h-screen text-lg'>
          <span>🚫 Initialization failed.</span>
        </div>
      </>
    )
  }

  switch (authStatus) {
    case AuthenticationStatus.Loading:
      return (
        <>
          <LocationLogger />
          <div className='flex items-center justify-center h-screen text-lg'>
            <div className='flex flex-row gap-1 items-center'>
              <Spinner />
              <span>Loading...</span>
            </div>
          </div>
        </>
      )

    case AuthenticationStatus.Authenticated:
      if (location.pathname === '/')
        return (
          <Navigate replace to={localStorage.getItem(CONSTANT.VIN_KEY) ? '/home' : '/my-car'} />
        )

      return (
        <>
          <LocationLogger />
          <Outlet />
        </>
      )

    default:
      return (
        <>
          <LocationLogger />
          <div className='flex items-center justify-center h-screen text-lg'>
            <span>🚫 Authentication failed.</span>
          </div>
        </>
      )
  }
}

const hasInitialQparams = (searchParams: URLSearchParams): boolean => {
  const requiredKeys = [CODE_QPARAM_KEY, STATE_QPARAM_KEY]

  const isValid: boolean = requiredKeys.reduce((a, b) => a && Boolean(searchParams.get(b)), true)

  return isValid
}

/**
 * Store keys from query parameters into local storage.
 * @param searchParams Query parameters.
 */
const storeKeys = (searchParams: URLSearchParams) => {
  const rawState = searchParams.get(STATE_QPARAM_KEY)
  if (!rawState) return

  const state: AuthState = JSON.parse(rawState)

  const language = getSupportedLanguage(state.lang)

  if (language) localStorage.setItem(CONSTANT.LANGUAGE_KEY, language)
  if (state.vin) localStorage.setItem(CONSTANT.VIN_KEY, state.vin)
}

/**
 * Get supported language.
 * @param rawLang Raw language from query params.
 * @returns Supported language.
 */
const getSupportedLanguage = (rawLang: string): string => {
  switch (rawLang) {
    case 'in':
    case 'id':
      return CONSTANT.ID_LOCALE
    default:
      return CONSTANT.ENUS_LOCALE
  }
}
