export const API = {
  // Subscription
  ACTIVE_CAR_URL: '/core/api/v1/subscription/bluelink/{vin}/status',
  ACTIVE_PACKAGE_URL: '/core/api/v1/subscription/bluelink/{vin}/active',
  PACKAGE_LIST_URL: '/core/api/v1/subscription/bluelink/{vin}',
  ORDER_DETAIL_URL: '/core/api/v1/subscription/bluelink/order/{planId}',
  TRX_HISTORY_URL: '/core/api/v1/subscription/bluelink/history',
  TRX_HISTORY_DETAIL_URL: '/core/api/v1/subscription/bluelink/history/{id}',
  CANCEL_ORDER_URL: '/core/api/v1/subscription/bluelink/order/{planId}/cancel',
  STOP_AUTO_RENEWAL_URL: '/core/api/v1/subscription/bluelink/order/{planId}/renewal/stop',

  // Subscription DDL
  PERIOD_LIST_URL: '/core/api/v1/subscription/duration/ddl',
  TRX_STATUS_LIST_URL: '/core/api/v1/subscription/status/ddl',
  PACKAGE_NAME_LIST_URL: '/core/api/v1/subscription/plan/ddl',
  PAYMENT_METHOD_LIST_URL: '/core/api/v1/subscription/paymentmethod/ddl',
  PAYMENT_GROUP_LIST_URL: '/core/api/v1/subscription/paymentmethod/group/ddl',

  // Vehicles
  CAR_LIST_URL: '/core/api/v1/vehicles',

  // Promo code
  CHECK_PROMO_CODE_URL: '/core/api/v1/promocode/{code}/check',

  // Copyright
  COPYRIGHT_URL: '/core/api/v1/copyright/{type}',
  LOCALIZATION_URL: '/core/api/v1/copyright/language/dictionary',

  // Language
  LANGUAGE_LIST_URL: '/core/api/v1/language/ddl',

  // Blob
  DOWNLOAD_URL: '/core/api/v1/blob/download',

  // Session
  SESSION_URL: '/core/api/v1/session/authcode',
  REFRESH_SESSION_URL: '/core/api/v1/session/refresh'
} as const
