import { useEffect, useState } from 'react'
import { matchPath, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { parseQueryParams } from './utils'

export const useDebounce = (value?: string, delay = 1000) => {
  const [debounceValue, setDebounceValue] = useState<string | undefined>(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounceValue(value)
    }, delay)

    return () => clearTimeout(handler)
  }, [value, delay])

  return debounceValue
}

export const useBack = () => {
  const location = useLocation()

  const { id } = useParams()

  const [searchParams] = useSearchParams()

  const navigate = useNavigate()

  const [backRoute, setBackRoute] = useState<string>()

  useEffect(() => {
    /**
     * @example
     * location.pathname = '/path/to/some/page'
     *
     * Result:
     * [
     *   '',
     *   'path',
     *   'to',
     *   'some',
     *   'page'
     * ]
     */
    const urlSegments = location.pathname.split('/')

    let route: string | undefined = undefined
    let qParams = ''

    for (const r of backableRoutes) {
      const matchResult = matchPath(r, location.pathname)
      if (!matchPath(r, location.pathname)) continue

      switch (matchResult?.pattern.path) {
        case '/subscription-plan': {
          route = '/home'
          break
        }

        case '/subscription-plan/order/:id': {
          const from = searchParams.get('from')
          route = from ? `/${from}` : '/subscription-plan'
          break
        }

        case '/subscription-plan/order/promo/:id':
        case '/subscription-plan/order/payment/:id': {
          route = urlSegments.filter((s) => !['promo', 'payment'].includes(s)).join('/')
          qParams = parseQueryParams(searchParams)
          break
        }

        case '/payment/:id': {
          route = id ? `/transaction-history/${id}` : '/transaction-history'
          break
        }

        case '/transaction-history/:id': {
          route = '/transaction-history'
          break
        }

        case '/tnc':
        case '/privacy-policy': {
          const packageId = searchParams.get('p')
          route = packageId ? `/subscription-plan/order/${packageId}` : '/settings'
          qParams = parseQueryParams(searchParams, undefined, ['p'])
          break
        }

        default:
      }
    }

    setBackRoute(route && [route, qParams].filter(Boolean).join('?'))
  }, [])

  return {
    backRoute,
    navigateBack: () => {
      if (backRoute) navigate(backRoute)
    }
  }
}

const backableRoutes = [
  '/subscription-plan',
  '/subscription-plan/order/:id',
  '/subscription-plan/order/promo/:id',
  '/subscription-plan/order/payment/:id',
  '/payment/:id',
  '/result',
  '/transaction-history/:id',
  '/tnc',
  '/privacy-policy'
]
