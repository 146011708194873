import type { ListProps } from 'global/types/props'
import Icon from 'shared/components/icon'
import { Empty } from 'shared/components/empty'

export const List = ({ list, selectedItem, onClick }: ListProps) => (
  <div>
    {list.length ? (
      list.map((item, id) => {
        if (onClick) {
          return (
            <button
              key={id + 1}
              className='flex flex-row items-center w-full px-4 py-3 text-left border-b border-border last-of-type:border-transparent'
              type='button'
              onClick={() => onClick(item.key)}
            >
              <div className='flex-1'>{item.value}</div>

              {item.key === selectedItem && (
                <Icon icon='checkmark-filled' size={20} className='text-secondary' />
              )}
            </button>
          )
        }

        return (
          <div
            key={id + 1}
            className='flex w-full px-4 py-3 border-b border-border last-of-type:border-transparent'
          >
            {item.value}
          </div>
        )
      })
    ) : (
      <Empty />
    )}
  </div>
)
