import { L10N } from 'global/localization'
import type { TrxHistoryDetailContentProps } from 'global/types/props'
import { Card } from 'shared/components/card'
import { Counter } from 'shared/components/counter'
import Icon from 'shared/components/icon'
import { formatCurrency, formatDate, formatDateTime } from 'shared/services/utils'
import { StatusBadge } from 'shared/components/status-badge'

export const TrxHistoryDetailContent = ({ trxDetail, onExpired }: TrxHistoryDetailContentProps) => (
  <>
    <h2 className='font-heading font-medium mb-4'>{L10N.LABEL.PAYMENT_INFORMATION}</h2>

    <Card>
      <div className='flex flex-row items-center justify-between gap-3 mb-3'>
        <div className='text-neutral text-sm'>{L10N.LABEL.STATUS}</div>
        <div className='font-medium text-sm text-right'>
          <StatusBadge label={trxDetail.statusLabel} value={trxDetail.invoiceStatus} />
        </div>
      </div>

      {trxDetail.invoiceStatus.toLowerCase() === 'pending' && (
        <div className='flex flex-col gap-2 items-center p-2 bg-background border border-border mb-3'>
          <div className='flex flex-col items-center text-sm mb-3'>
            <div>{L10N.LABEL.EXPIRED_IN}</div>
            <div className='font-medium'>{formatDateTime(trxDetail.expiredDate, true)}</div>
          </div>
          <Counter
            type='count-down'
            date={trxDetail.expiredDate}
            showSublabel
            onTimeUp={onExpired}
          />
        </div>
      )}

      <div className='flex flex-row items-center justify-between gap-3 mb-3'>
        <div className='text-neutral text-sm'>{L10N.LABEL.INVOICE_ID}</div>
        <div className='font-medium text-sm text-right'>{trxDetail.invoiceNo}</div>
      </div>

      <div className='flex flex-row items-center justify-between gap-3 mb-3'>
        <div className='text-neutral text-sm'>{L10N.LABEL.TRANSACTION_DATE}</div>
        <div className='font-medium text-sm text-right'>
          {trxDetail.invoiceStatus.toLowerCase() === 'paid'
            ? trxDetail.xendit.paidAt
              ? formatDateTime(trxDetail.xendit.paidAt)
              : '-'
            : trxDetail.transactionDate
              ? formatDateTime(trxDetail.transactionDate)
              : '-'}
        </div>
      </div>

      {trxDetail.invoiceStatus.toLowerCase() === 'paid' && (
        <div className='flex flex-row items-center justify-between gap-3 mb-3'>
          <div className='text-neutral text-sm'>{L10N.LABEL.PAYMENT_METHOD}</div>
          <div className='font-medium text-sm text-right'>
            {trxDetail.paymentFee.paymentMethod ?? '-'}
          </div>
        </div>
      )}

      <div className='flex flex-row items-center justify-between gap-3'>
        <div className='text-neutral text-sm'>{L10N.LABEL.TOTAL_PAYMENT}</div>
        <div className='font-medium text-sm text-right'>
          {formatCurrency(trxDetail.paymentFee.total)}
        </div>
      </div>
    </Card>

    <h2 className='font-heading font-medium mb-4'>{L10N.LABEL.PURCHASE_DETAIL}</h2>

    <Card>
      <div className='flex flex-row items-center mb-4'>
        <img
          src={trxDetail.iconUrl || 'https://dummyimage.com/32'}
          alt='subscription-package'
          className='size-11 rounded-full mr-4'
        />
        <div className='flex flex-col'>
          <div className='text-sm'>{L10N.LABEL.PLAN}</div>
          <div className='font-medium text-sm'>
            {trxDetail.name} - {trxDetail.durationDescription}
          </div>
        </div>
      </div>

      <div className='flex flex-row items-center justify-between mb-3 last:mb-0'>
        <div className='text-sm text-neutral'>{L10N.LABEL.SUBSCRIPTION_END_DATE}</div>
        <div className='font-medium text-sm text-right'>
          {formatDate(trxDetail.subscription.serviceEndDate)}
        </div>
      </div>

      {trxDetail.isAutoRenewal && (
        <div className='flex flex-row gap-2 items-center p-2 bg-background border border-border'>
          <Icon icon='calendar-check' size={24} />

          <div className='flex-1'>
            <h3 className='text-sm'>{L10N.LABEL.AUTO_RENEWAL}</h3>
            <div className='text-neutral text-xs'>
              {L10N.formatString(
                L10N.MESSAGE.AUTO_RENEWAL_DESC,
                formatDate(trxDetail.subscription.nextPaymentDate),
                trxDetail.duration.toLowerCase() === 'monthly'
                  ? L10N.LABEL.MONTH.toLowerCase()
                  : L10N.LABEL.YEAR.toLowerCase()
              )}
            </div>
          </div>
        </div>
      )}
    </Card>

    <h2 className='font-heading font-medium mb-4'>{L10N.LABEL.PAYMENT_DETAIL}</h2>

    <Card>
      <div className='flex flex-row items-center justify-between mb-3'>
        <div className='text-sm'>{L10N.LABEL.PRICE}</div>
        <div className='font-medium text-sm text-right'>
          {formatCurrency(trxDetail.paymentFee.price)}
        </div>
      </div>

      <div className='flex flex-row items-center justify-between mb-3'>
        <div className='text-sm'>{L10N.LABEL.DISCOUNT}</div>
        <div className='font-medium text-sm text-right'>
          {(trxDetail.paymentFee.discount ?? 0) > 0 && '-'}
          {formatCurrency(trxDetail.paymentFee.discount ?? 0)}
        </div>
      </div>

      {trxDetail.paymentFee.promo && (
        <div className='flex flex-row items-center justify-between mb-3'>
          <div className='text-sm'>{L10N.LABEL.PROMO_CODE}</div>
          <div className='font-medium text-sm text-right'>
            {trxDetail.paymentFee.promo > 0 && '-'}
            {formatCurrency(trxDetail.paymentFee.promo)}
          </div>
        </div>
      )}

      <div className='flex flex-row items-center justify-between mb-3'>
        <div className='text-sm'>{L10N.LABEL.VAT}</div>
        <div className='font-medium text-sm text-right'>
          {formatCurrency(trxDetail.paymentFee.vat)}
        </div>
      </div>

      <hr className='border border-border mb-3' />

      <div className='flex flex-row items-center justify-between'>
        <div className='font-bold text-sm'>{L10N.LABEL.TOTAL_INCLUDING_TAX}</div>
        <div className='font-bold text-sm text-right'>
          {formatCurrency(trxDetail.paymentFee.total)}
        </div>
      </div>
    </Card>
  </>
)
