import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CONSTANT } from 'global/constant'
import { L10N } from 'global/localization'
import { LayoutType } from 'global/types/enums'
import type { SettingsListItemProps } from 'global/types/props'
import type { LanguageResponse } from 'global/types/responses'
import { useGetLanguagesQuery } from 'store/language'
import { Layout } from 'shared/components/layout'
import { ActionSheet } from 'shared/components/action-sheet'
import Icon from 'shared/components/icon'
import { Spinner } from 'shared/components/spinner'
import { SettingsListItem } from './list-item'

export const Settings = () => {
  const navigate = useNavigate()

  const { data: languageList, isLoading } = useGetLanguagesQuery()

  const [showLangPicker, setShowLangPicker] = useState<boolean>(false)

  const list: SettingsListItemProps[] = [
    {
      name: L10N.LABEL.LANGUAGE,
      icon: 'translate',
      value: isLoading ? (
        <Spinner />
      ) : (
        <>
          {
            languageList?.find(
              (l) =>
                l.value === (localStorage.getItem(CONSTANT.LANGUAGE_KEY) ?? CONSTANT.ENUS_LOCALE)
            )?.text ?? ''
          }
        </>
      ),
    },
    {
      name: L10N.LABEL.TERMS_CONDITIONS,
      icon: 'file-tnc',
      onClick: () => navigate('/tnc')
    },
    {
      name: L10N.LABEL.PRIVACY_POLICY,
      icon: 'file-privacy',
      onClick: () => navigate('/privacy-policy')
    }
  ]

  return (
    <Layout title={L10N.LABEL.SETTINGS} type={LayoutType.TabFooter} noPadding>
      <div className='animate-fadeIn'>
        <h2 className='font-heading font-medium m-4'>{L10N.LABEL.SETTINGS}</h2>

        <div className='bg-white border-y border-border px-4 mb-4'>
          {list.map((item, id) => (
            <SettingsListItem key={id + 1} {...item} />
          ))}
        </div>
      </div>

      <ActionSheet
        title={L10N.LABEL.LANGUAGE}
        open={showLangPicker}
        onClose={() => setShowLangPicker(false)}
      >
        <div className='px-4'>
          {languageList?.map((lang, id) => (
            <div
              key={id + 1}
              className='flex flex-row items-center gap-2 border-b border-border w-full py-3 text-left'
              // type='button'
              // onClick={() => {
              //   setShowLangPicker(false)
              //   localStorage.setItem(CONSTANT.LANGUAGE_KEY, lang.value)
              //   L10N.setLanguage(lang.value)

              //   dispatch(api.util.invalidateTags([CONSTANT.LANG_TAG]))
              // }}
            >
              <img src={lang.image} alt={lang.value} className='size-5 rounded-full' />
              <div className='flex-1'>{lang.text}</div>
              {isLangSelected(lang) && (
                <Icon icon='checkmark-filled' size={20} className='text-secondary' />
              )}
            </div>
          ))}
        </div>
      </ActionSheet>
    </Layout>
  )
}

const isLangSelected = (lang: LanguageResponse): boolean =>
  localStorage.getItem(CONSTANT.LANGUAGE_KEY) === lang.value
