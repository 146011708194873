import { useDispatch } from 'react-redux'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { api } from './api'
import { layoutReducer } from './slices/layout'
import { locationLogsReducer } from './slices/location-logs'
import { orderReducer } from './slices/order'
import { paymentReducer } from './slices/payment'
import { errorToast } from './middlewares/error-toast'

const reducer = combineReducers({
  layout: layoutReducer,
  locationLogs: locationLogsReducer,
  order: orderReducer,
  payment: paymentReducer,
  [api.reducerPath]: api.reducer
})

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware).concat(errorToast)
})

type AppDispatch = typeof store.dispatch

export type RootState = ReturnType<typeof store.getState>

export const useAppDispatch = useDispatch.withTypes<AppDispatch>()

setupListeners(store.dispatch)
