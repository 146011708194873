import { L10N } from 'global/localization'
import { ButtonColor } from 'global/types/enums'
import type { EnterPromoFooterProps } from 'global/types/props'
import { Button } from 'shared/components/button'
import { formatCurrency } from 'shared/services/utils'

export const EnterPromoFooter = ({ total, canSubmit = false, onSubmit }: EnterPromoFooterProps) => (
  <div className='flex flex-row items-center justify-between p-4'>
    <div className='flex flex-col'>
      <div className='text-xs text-neutral'>{L10N.LABEL.YOU_SAVE}</div>
      <div className='font-heading font-medium text-lg'>{formatCurrency(total)}</div>
    </div>

    <Button
      label={L10N.LABEL.USE_PROMO_CODE}
      color={ButtonColor.Primary}
      disabled={!canSubmit}
      onClick={onSubmit}
    />
  </div>
)
