import { useEffect, useState } from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useAppDispatch } from 'store/store'
import { addLocationLog, getLocationLogsState } from 'store/slices/location-logs'

export const LocationLogger = () => {
  const location = useLocation()

  const dispatch = useAppDispatch()

  const { logs } = useSelector(getLocationLogsState)

  const [showLogs, setShowLogs] = useState<boolean>(false)

  useEffect(() => {
    if (location.pathname === '/' && location.search) {
      dispatch(addLocationLog({ url: window.location.href, timestamp: new Date().toISOString() }))
    }
  }, [location])

  return (
    <div className='fixed w-full z-50'>
      <div
        className={[
          'break-words max-h-64 overflow-y-auto text-sm border-border border-b bg-white',
          showLogs ? '' : 'hidden'
        ].join(' ')}
        // style={{ height: showLogs ? 'calc-size(auto, size)' : '0px' }}
      >
        <div className='p-4'>
          {logs.length ? (
            <ul>
              {logs.map((log, id) => (
                <li key={id + 1} className='border-border border-b last:border-b-0 py-2'>
                  <div className='text-secondary'>
                    {moment(log.timestamp).format('ddd, D MMM YYYY HH:mm:ss')}
                  </div>
                  {log.url}
                </li>
              ))}
            </ul>
          ) : (
            <>Nothing found.</>
          )}
        </div>
      </div>
      <button
        className={`bg-secondary text-white text-xs px-4 py-1 ${showLogs ? '' : 'opacity-50'}`}
        onClick={() => setShowLogs(!showLogs)}
      >
        {showLogs ? 'Hide' : 'Show'} URL {logs.length ? `(${logs.length})` : ''}
      </button>
    </div>
  )
}
