import moment from 'moment'
import queryString from 'query-string'
import { CONSTANT } from 'global/constant'

/**
 * Parse query parameters.
 * @param searchParams Query/search parameters.
 * @param additionalData Additional data to include in query params.
 * @param keysToRemove Keys to remove from query params.
 * @returns Parsed query parameters.
 */
export const parseQueryParams = (
  searchParams: URLSearchParams,
  additionalData?: Record<string, string | number>,
  keysToRemove?: string[]
) => {
  const qParams: Record<string, string> = Array.from(searchParams.entries()).reduce(
    (a, [key, value]) => ({ ...a, [key]: value }),
    {}
  )

  if (keysToRemove?.length) {
    for (const key of keysToRemove) delete qParams[key]
  }

  return queryString.stringify({ ...qParams, ...additionalData })
}

/**
 * Open external link that will be handled by RN Webview if possible.
 * @param url URL to open.
 * @param action Action to do.
 */
export const openExternalLink = (url: string, action: 'open' | 'download' = 'open') => {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(JSON.stringify({ action, url }))
  } else if (window.Android) {
    window.Android.openExternalBrowser(url)
  } else if (window.webkit) {
    window.webkit.messageHandlers.openExternalBrowser.postMessage(url)
  } else {
    window.open(url)
  }
}

/**
 * Format a currency value.
 * @param value Value to format.
 * @param locale Locale.
 * @returns Formatted currency.
 */
export const formatCurrency = (value: number, locale?: string): string => {
  let format = ''
  let localeString = locale

  switch (locale) {
    default:
      format = CONSTANT.ID_CURRENCY_FORMAT
      localeString = undefined
  }

  return format.replace('#', value.toLocaleString(localeString))
}

/**
 * Format a date.
 * @param value Date to format.
 * @param long Use long format? Defaults to `false`.
 * @returns Formatted date.
 */
export const formatDate = (value: moment.MomentInput, long = false): string =>
  moment(value).format(long ? CONSTANT.LONG_DATE_FORMAT : CONSTANT.SHORT_DATE_FORMAT)

/**
 * Format a date time.
 * @param value Date time to format.
 * @param long Use long format? Defaults to `false`.
 * @returns Formatted date time.
 */
export const formatDateTime = (value: moment.MomentInput, long = false): string =>
  moment(value).format(long ? CONSTANT.LONG_DATETIME_FORMAT : CONSTANT.SHORT_DATETIME_FORMAT)
