import { useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { L10N } from 'global/localization'
import { LayoutType } from 'global/types/enums'
import { CONSTANT } from 'global/constant'
import { useAppDispatch } from 'store/store'
import {
  useGetActiveCarQuery,
  useLazyGetOrderDetailQuery,
  useSubmitOrderMutation
} from 'store/subscription'
import { getOrderState, setPaymentPrice, setPaymentTotal } from 'store/slices/order'
import { setPaymentUrl } from 'store/slices/payment'
import { DataHandler } from 'shared/components/data-handler'
import { Layout } from 'shared/components/layout'
import { Empty } from 'shared/components/empty'
import { parseQueryParams } from 'shared/services/utils'
import { OrderDetailContent } from './content'
import { OrderDetailFooter } from './footer'

export const OrderDetail = () => {
  const { id } = useParams()

  const [searchParams] = useSearchParams()

  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const order = useSelector(getOrderState)

  const activeCarResult = useGetActiveCarQuery(localStorage.getItem(CONSTANT.VIN_KEY) ?? '')

  const [getOrderDetail, orderDetailResult] = useLazyGetOrderDetailQuery()

  const [submitOrder, submitOrderResult] = useSubmitOrderMutation()

  useEffect(() => {
    getOrderDetail({
      planId: id ?? '0',
      vin: localStorage.getItem(CONSTANT.VIN_KEY) ?? '',
      promoCode: order.promoCode
    })
  }, [])

  useEffect(() => {
    if (!orderDetailResult.isSuccess || !orderDetailResult.data) return

    dispatch(setPaymentPrice(orderDetailResult.data.price))
    dispatch(setPaymentTotal(orderDetailResult.data.paymentFee.total))
  }, [orderDetailResult.data])

  useEffect(() => {
    if (!submitOrderResult.isSuccess || !submitOrderResult.data) return

    dispatch(setPaymentUrl(submitOrderResult.data.xendit.invoiceAction.url))
    navigate(`/payment/${submitOrderResult.data.id}`)
  }, [submitOrderResult.data])

  const openEnterPromoPage = () => {
    navigate([`/subscription-plan/order/promo/${id}`, parseQueryParams(searchParams)].join('?'))
  }

  const openTncPage = (showAction: boolean) => {
    navigate(
      ['/tnc', parseQueryParams(searchParams, showAction && id ? { p: id } : undefined)].join('?')
    )
  }

  const openPrivacyPolicyPage = (showAction: boolean) => {
    navigate(
      [
        '/privacy-policy',
        parseQueryParams(searchParams, showAction && id ? { p: id } : undefined)
      ].join('?')
    )
  }

  /**
   * Open Choose payment page, or directly submit the order if auto renewal is ON.
   */
  const openChoosePaymentPage = () => {
    if (order.isEnableAutoRenewal) {
      submitOrder({
        planId: Number(id),
        vin: localStorage.getItem(CONSTANT.VIN_KEY) ?? '',
        autoRenewal: order.isEnableAutoRenewal,
        isAgreeTnc: order.isAgreeTnc,
        isAgreePnp: order.isAgreePrivacyPolicy,
        promoCode: order.promoCode
      })
      return
    }

    navigate([`/subscription-plan/order/payment/${id}`, parseQueryParams(searchParams)].join('?'))
  }

  const canSubmit = () =>
    Boolean(orderDetailResult.data?.planId) && order.isAgreeTnc && order.isAgreePrivacyPolicy

  return (
    <Layout
      title={L10N.LABEL.ORDER_DETAIL}
      type={LayoutType.HeaderFooter}
      footerComponent={
        <OrderDetailFooter
          total={order.paymentTotal}
          canSubmit={canSubmit()}
          submitLoading={submitOrderResult.isLoading}
          onSubmit={openChoosePaymentPage}
        />
      }
    >
      <DataHandler {...activeCarResult}>
        {activeCarResult.data ? (
          <OrderDetailContent
            car={activeCarResult.data}
            isLoading={orderDetailResult.isLoading}
            orderDetail={orderDetailResult.data}
            onEnterPromo={openEnterPromoPage}
            onClickTnc={openTncPage}
            onClickPolicy={openPrivacyPolicyPage}
          />
        ) : (
          <Empty />
        )}
      </DataHandler>
    </Layout>
  )
}
