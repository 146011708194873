import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { L10N } from 'global/localization'
import { LayoutType } from 'global/types/enums'
import { Layout } from 'shared/components/layout'
import { getPaymentState } from 'store/slices/payment'

export const Payment = () => {
  const payment = useSelector(getPaymentState)

  if (!payment.paymentUrl) return <Navigate to='/home' />

  return (
    <Layout title={L10N.LABEL.PAYMENT} type={LayoutType.Header} noPadding>
      <iframe title='Xendit Payment' src={payment.paymentUrl} className='w-full h-full' />
    </Layout>
  )
}
