import { useEffect, useState } from 'react'
import { L10N } from 'global/localization'
import { ButtonColor, SelectType } from 'global/types/enums'
import type { KeyValueProps, TrxFilterValuesProps, TrxHistoryFilterProps } from 'global/types/props'
import type { DdlResponse } from 'global/types/responses'
import {
  useLazyGetPackageNameListQuery,
  useLazyGetPaymentMethodListQuery,
  useLazyGetTrxStatusListQuery
} from 'store/subscription'
import { ActionSheet } from 'shared/components/action-sheet'
import { DataHandler } from 'shared/components/data-handler'
import { Select } from 'shared/components/select'
import { Button } from 'shared/components/button'
import { DatePicker } from 'shared/components/date-picker'
import { MultiSelect } from 'shared/components/multi-select'
import { Empty } from 'shared/components/empty'
import { getFilterDefaultValues } from './page'

export const TrxHistoryFilter = ({
  open,
  values,
  onClose,
  onReset,
  onApply
}: TrxHistoryFilterProps) => {
  const [getTrxStatusList, getTrxStatusListResult] = useLazyGetTrxStatusListQuery()

  const [getPackageNameList, getPackageNameListResult] = useLazyGetPackageNameListQuery()

  const [getPaymentMethodList, getPaymentMethodListResult] = useLazyGetPaymentMethodListQuery()

  const sortList = [
    L10N.LABEL.NEWEST,
    L10N.LABEL.OLDEST,
    L10N.LABEL.ASCENDING,
    L10N.LABEL.DESCENDING
  ]

  const [filterValues, setFilterValues] = useState<TrxFilterValuesProps>(values)

  useEffect(() => {
    if (!open) return

    setFilterValues(values)
  }, [open, values])

  useEffect(() => {
    if (!open || (getTrxStatusListResult.data && getPackageNameListResult.data)) return

    if (!getTrxStatusListResult.data) {
      getTrxStatusList().unwrap().catch(console.error)
    }

    if (!getPackageNameListResult.data) {
      getPackageNameList().unwrap().catch(console.error)
    }

    if (!getPaymentMethodListResult.data) {
      getPaymentMethodList().unwrap().catch(console.error)
    }
  }, [
    open,
    getTrxStatusListResult.data,
    getPackageNameListResult.data,
    getPaymentMethodListResult.data
  ])

  return (
    <ActionSheet title={L10N.LABEL.FILTER_SORT} open={open} onClose={onClose}>
      <div className='p-4'>
        <DataHandler
          isLoading={
            getTrxStatusListResult.isLoading ||
            getPackageNameListResult.isLoading ||
            getPaymentMethodListResult.isLoading
          }
          error={
            getTrxStatusListResult.error ||
            getPackageNameListResult.error ||
            getPaymentMethodListResult.error
          }
          noAnimation
        >
          {getTrxStatusListResult.data &&
          getPackageNameListResult.data &&
          getPaymentMethodListResult.data ? (
            <>
              <MultiSelect
                label={L10N.LABEL.TRANSACTION_STATUS}
                options={getOptions(getTrxStatusListResult.data)}
                values={filterValues.paymentStatus}
                onSelect={(paymentStatus) =>
                  setFilterValues((prevValues) => ({ ...prevValues, paymentStatus }))
                }
              />

              <Select
                label={L10N.LABEL.SUBSCRIPTION_PLAN}
                options={getOptions(getPackageNameListResult.data)}
                value={filterValues.subscriptionPlan?.toString()}
                onSelect={(option) => {
                  const subscriptionPlan = getPackageNameListResult.data?.find(
                    (d) => d.value === option
                  )?.value

                  setFilterValues((prevValues) => ({ ...prevValues, subscriptionPlan }))
                }}
              />

              <Select
                label={L10N.LABEL.PAYMENT_METHOD}
                options={getOptions(getPaymentMethodListResult.data)}
                value={filterValues.paymentMethod}
                onSelect={(option) => {
                  const paymentMethod = getPaymentMethodListResult.data?.find(
                    (d) => d.value === option
                  )?.value

                  setFilterValues((prevValues) => ({ ...prevValues, paymentMethod }))
                }}
              />

              <DatePicker
                label={L10N.LABEL.TRANSACTION_DATE}
                value={filterValues.transactionDate}
                onSelect={(transactionDate) => {
                  setFilterValues((prevValues) => ({ ...prevValues, transactionDate }))
                }}
              />

              <Select
                label={L10N.LABEL.SORTING}
                type={SelectType.Pills}
                options={sortList.map((o) => ({ key: o, value: o }))}
                value={filterValues.sort}
                onSelect={(sort) => setFilterValues((prevValues) => ({ ...prevValues, sort }))}
              />

              <div className='grid grid-cols-2 gap-4'>
                <Button
                  label={L10N.LABEL.RESET}
                  color={ButtonColor.WhiteBordered}
                  onClick={() => onReset(getFilterDefaultValues())}
                />
                <Button
                  label={L10N.LABEL.APPLY}
                  color={ButtonColor.Primary}
                  onClick={() => onApply(filterValues)}
                />
              </div>
            </>
          ) : (
            <Empty />
          )}
        </DataHandler>
      </div>
    </ActionSheet>
  )
}

const getOptions = (rawOptions: DdlResponse[], withGroup = false): KeyValueProps[] =>
  rawOptions.map((option) => ({
    key: option.value,
    value: [option.text, withGroup ? option.group : ''].filter(Boolean).join(' - ')
  }))
