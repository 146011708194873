import { useEffect, useMemo, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { CONSTANT } from 'global/constant'
import { getAppCheckToken } from 'global/firebase'
import { useDebounce } from 'shared/services/hooks'
import { Spinner } from 'shared/components/spinner'

export const PdfViewerDemo = () => {
  const [appCheckToken, setAppCheckToken] = useState<string>()

  const [width, setWidth] = useState<number>(0)

  const [pageCount, setPageCount] = useState<number>(0)

  const debounceWidth = useDebounce(String(width))

  const options = useMemo(
    () => ({
      httpHeaders: {
        Authorization: `Bearer ${localStorage.getItem(CONSTANT.TOKEN_KEY) ?? ''}`,
        'X-SessionId': localStorage.getItem(CONSTANT.SESSION_ID_KEY) ?? '',
        'X-AppCheck-Token': appCheckToken
        // 'Accept-Language': localStorage.getItem(CONSTANT.LANGUAGE_KEY) ?? CONSTANT.ENUS_LOCALE
      }
    }),
    [localStorage, appCheckToken]
  )

  useEffect(() => {
    setup()

    window.addEventListener('load', updateWidth)
    window.addEventListener('resize', updateWidth)

    return () => {
      window.removeEventListener('load', updateWidth)
      window.removeEventListener('resize', updateWidth)
    }
  }, [])

  const updateWidth = () => {
    setWidth(document.getElementById('pdfContent')?.offsetWidth ?? 0)
  }

  const setup = async () => setAppCheckToken(await getAppCheckToken())

  if (!appCheckToken) return <>Loading...</>

  return (
    <div className='bg-background p-4 max-h-96 overflow-y-auto'>
      <div id='pdfContent'>
        {Boolean(Number(debounceWidth)) && (
          <Document
            file='https://stg-api-bluelinksubscription.hyundaimotor.co.id/core/api/v1/blob/download?fileUrl=https%3A%2F%2Fblusubsstgsa.blob.core.windows.net%2Fsubscription%2Fdocument%2Ftnc%2Ftnc-enus.pdf'
            loading={
              <div className='flex items-center justify-center'>
                <Spinner />
              </div>
            }
            onLoadSuccess={(pdf) => setPageCount(pdf.numPages)}
            options={options}
          >
            {Array.from(Array(pageCount).keys()).map((id) => (
              <Page
                key={id + 1}
                width={Number(debounceWidth)}
                pageNumber={id + 1}
                className='mb-4'
                onClick={(e) => console.log('e', e)}
              />
            ))}
          </Document>
        )}
      </div>
    </div>
  )
}

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString()
