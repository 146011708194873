import { L10N } from 'global/localization'
import { ButtonColor } from 'global/types/enums'
import type { TncFooterProps } from 'global/types/props'
import { Button } from 'shared/components/button'

export const TncFooter = ({ onClick }: TncFooterProps) => (
  <div className='grid grid-cols-1 p-4 bg-white'>
    <Button label={L10N.LABEL.I_AGREE} color={ButtonColor.Primary} onClick={onClick} />
  </div>
)
