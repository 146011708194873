import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import queryString from 'query-string'
import { API } from 'global/api'
import { CONSTANT } from 'global/constant'
import { getAppCheckToken } from 'global/firebase'
import type { RefreshSessionParam } from 'global/types/params'
import type { BaseResponse, SessionResponse } from 'global/types/responses'

const basicAuthApis: string[] = [API.LOCALIZATION_URL, API.SESSION_URL, API.REFRESH_SESSION_URL]

export const headers = (
  {
    useContentType = true,
    contentType = 'application/json'
  }: {
    useContentType?: boolean
    contentType?: string
  } = { useContentType: true, contentType: 'application/json' }
) => {
  const currentLang = localStorage.getItem(CONSTANT.LANGUAGE_KEY) ?? CONSTANT.ENUS_LOCALE

  const header = new Headers()

  header.append('Accept-Language', currentLang)
  header.append('Access-Control-Allow-Origin', '*')
  header.append('X-AppVersion', 'BluelinkSubscription')
  header.append('X-DeviceId', 'BluelinkSubscription')
  header.append('X-Language', currentLang)

  if (useContentType) {
    header.append('Content-Type', contentType)
  }

  return header
}

const baseQuery = fetchBaseQuery({
  baseUrl: CONSTANT.BASE_URL,
  prepareHeaders: async (headers, api) => {
    const token = localStorage.getItem(CONSTANT.TOKEN_KEY)
    headers.set(
      'Authorization',
      typeof api.arg !== 'string' && !basicAuthApis.includes(api.arg.url)
        ? `Bearer ${token}`
        : `Basic ${process.env.REACT_APP_AUTH_BASIC_TOKEN}`
    )

    const sessionId = localStorage.getItem(CONSTANT.SESSION_ID_KEY)
    if (sessionId) headers.set('X-SessionId', sessionId)

    headers.set('X-AppCheckToken', await getAppCheckToken())
  },
  paramsSerializer: queryString.stringify,
  validateStatus: (response, result: BaseResponse<unknown> | undefined) =>
    response.ok && !result?.error
})

const fetchBase: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const result = await baseQuery(args, api, extraOptions)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (result.data) return { ...result, data: (result.data as BaseResponse<any>).data }

  if (result.error) {
    switch (result.error.status) {
      case 401: {
        const body: RefreshSessionParam = {
          token: localStorage.getItem(CONSTANT.TOKEN_KEY) ?? '',
          sessionId: localStorage.getItem(CONSTANT.SESSION_ID_KEY) ?? ''
        }

        const refreshResult = await baseQuery(
          {
            url: API.REFRESH_SESSION_URL,
            method: 'POST',
            headers: headers(),
            body
          },
          api,
          extraOptions
        )

        // Clear all stored data if failed.
        if (refreshResult.error) {
          localStorage.clear()
          location.reload()
        }

        const refreshData = refreshResult.data as BaseResponse<SessionResponse>

        // Update stored session with the new one.
        localStorage.setItem(CONSTANT.TOKEN_KEY, refreshData.data.token)
        localStorage.setItem(CONSTANT.SESSION_ID_KEY, refreshData.data.sessionId)

        return await baseQuery(args, api, extraOptions)
      }
      default: {
        const error = result.error
        if (error.data) {
          error.data = (error.data as BaseResponse<unknown>)?.error

          return { ...result, error }
        } else {
          return result
        }
      }
    }
  }

  return result
}

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBase,
  tagTypes: [CONSTANT.CAR_TAG, CONSTANT.LANG_TAG, CONSTANT.TRANSACTION_TAG],
  endpoints: () => ({})
})
