import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { PaymentInitialState } from 'global/types/states'
import type { RootState } from 'store/store'

const initialState: PaymentInitialState = {
  paymentUrl: ''
}

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setPaymentUrl: (state, action: PayloadAction<string>) => {
      state.paymentUrl = action.payload
    }
  }
})

export const { setPaymentUrl } = paymentSlice.actions

export const getPaymentState = (state: RootState) => state.payment

export const paymentReducer = paymentSlice.reducer
