import type { KeyValueProps, MultiSelectProps } from 'global/types/props'

export const MultiSelect = ({ label, options, values = [], onSelect }: MultiSelectProps) => {
  const selectOption = (option: KeyValueProps['key']) => {
    const selectedOption = values.find((v) => v === option)

    let newOptions: KeyValueProps['key'][] = []
    if (selectedOption) newOptions = values.filter((v) => v !== selectedOption)
    else newOptions = [...values, option]

    onSelect?.(newOptions)
  }

  return (
    <>
      {label && <div className='mb-1'>{label}</div>}
      <div className='flex flex-wrap gap-2 mb-4'>
        {options.map((item, id) => (
          <button
            key={id + 1}
            className={[
              'px-2 py-1 text-sm',
              values.find((o) => o === item.key)
                ? 'bg-secondary text-white'
                : 'bg-background text-black'
            ].join(' ')}
            type='button'
            onClick={() => selectOption(item.key)}
          >
            {item.value}
          </button>
        ))}
      </div>
    </>
  )
}
