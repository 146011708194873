import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { L10N } from 'global/localization'
import { useGetTncQuery } from 'store/copyright'
import { useAppDispatch } from 'store/store'
import { setAgreeTnc } from 'store/slices/order'
import { Layout } from 'shared/components/layout'
import { DataHandler } from 'shared/components/data-handler'
import { PdfViewer } from 'shared/components/pdf-viewer'
import { Empty } from 'shared/components/empty'
import { useBack } from 'shared/services/hooks'
import { TncFooter } from './footer'

const PACKAGE_QPARAM_KEY = 'p'

export const Tnc = () => {
  const { navigateBack } = useBack()

  const dispatch = useAppDispatch()

  const [searchParams] = useSearchParams()

  const tncData = useGetTncQuery()

  const [isPdfLoaded, setIsPdfLoaded] = useState<boolean>(false)

  const agree = () => {
    dispatch(setAgreeTnc(true))

    navigateBack()
  }

  return (
    <Layout title={L10N.LABEL.TERMS_CONDITIONS} noPadding>
      <DataHandler {...tncData}>
        {/* <div
          className='prose overflow-auto my-4 p-4 bg-white'
          dangerouslySetInnerHTML={{ __html: decode(tncData.data?.content ?? '') }}
        /> */}

        {tncData.data ? (
          <>
            <div className={['p-4', !isPdfLoaded ? 'h-full' : ''].join(' ')}>
              <PdfViewer url={tncData.data.content} onComplete={() => setIsPdfLoaded(true)} />
            </div>

            {searchParams.get(PACKAGE_QPARAM_KEY) && isPdfLoaded ? (
              <TncFooter onClick={agree} />
            ) : undefined}
          </>
        ) : (
          <Empty />
        )}
      </DataHandler>
    </Layout>
  )
}
